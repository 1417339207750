import React, { Component } from 'react';

import { ajax_wrapper, get_global_state } from 'functions';
import { Stripe, ThankYou } from 'pages';
import { Button } from 'library';

class FileForMe extends Component {
    render() {
        return (
            <div
                className="gradient-border simple-card"
                style={{
                    padding: '2px',
                    borderRadius: '16px',
                }}
            >
                <div
                    className="gradient-border-content"
                    style={{
                        overflow: 'hidden',
                        borderRadius: '16px',
                        margin: '0px',
                        padding: '20px',
                    }}
                >
                    <h4>File For Me</h4>
                    <h4>
                        <span className="gradient-color">
                            (GUARANTEED SAVINGS)
                        </span>
                    </h4>
                    <div className="page-break"></div>
                    <h1>${this.props.price}</h1>

                    <div className="page-break"></div>
                    <ul>
                        <li>
                            Skip the hassle of paperwork and filings—we'll
                            prepare and submit everything on your behalf.
                        </li>
                        <li>
                            No confusing and time confusing paperwork or
                            government office visits.
                        </li>
                        <li>
                            <strong>100% money-back guarantee</strong> if your
                            application isn’t accepted.
                        </li>
                    </ul>
                    <Button
                        className="gradient-border"
                        onClick={this.props.file_for_me}
                        disabled={this.props.disabled ? 'disabled' : null}
                    >
                        <div
                            className="gradient-border-content"
                            style={{ fontWeight: 500 }}
                        >
                            {'File For Me'}
                        </div>
                    </Button>
                </div>
            </div>
        );
    }
}

export default class FilingOptions extends Component {
    constructor(props) {
        super(props);

        this.state = {
            page: 'filing_options',
            cost: 2000,
        };

        this.diy = this.diy.bind(this);
        this.file_for_me = this.file_for_me.bind(this);
    }

    diy() {
        let params = get_global_state()['params'];
        let data = {
            exemption_type: this.props.filing_options.name,
            diy: true,
            property_id: params['property_id'],
            property_dict: this.props.property,
            exemption_id: this.props.filing_options.id,
        };
        if (params['email']) {
            data['email'] = params['email'];
        }
        ajax_wrapper('POST', '/api/want_to_file/', data, () =>
            this.setState({ page: 'thank_you' }),
        );

        window.dataLayer.push({
            exemption: this.props.filing_options.name,
            event: 'diy',
        });

        let session_id = localStorage.getItem('session_id');
        if (session_id) {
            let unique_data = { exemption: this.props.filing_options };
            let event_data = {
                session_id: session_id,
                event_name: 'diy',
                event_data: unique_data,
            };
            ajax_wrapper('POST', '/api/create_event/', event_data, console.log);
        }

        let ad_tracker_id = localStorage.getItem('ad_tracker');
        if (ad_tracker_id) {
            ajax_wrapper(
                'POST',
                '/api/facebook_ad_tracker/',
                { id: ad_tracker_id, filing_status: 'DIY' },
                console.log,
            );
        }
    }

    file_for_me() {
        window.dataLayer.push({
            exemption: this.props.filing_options.name,
            event: 'file_for_me',
        });

        let session_id = localStorage.getItem('session_id');
        if (session_id) {
            let unique_data = { exemption: this.props.filing_options };
            let event_data = {
                session_id: session_id,
                event_name: 'file_for_me',
                event_data: unique_data,
            };
            ajax_wrapper('POST', '/api/create_event/', event_data, console.log);
        }

        window.secret_react_state.filing_options = this.props.filing_options;
        window.secret_react_state.property_dict = this.props.property;

        let params = get_global_state()['params'];
        let data = {
            exemption_type: this.props.filing_options.name,
            diy: false,
            property_id: params['property_id'],
            property_dict: this.props.property,
            exemption_id: this.props.filing_options.id,
        };
        if (params['email']) {
            data['email'] = params['email'];
        }

        ajax_wrapper('POST', '/api/want_to_file/', data, console.log);

        let ad_tracker_id = localStorage.getItem('ad_tracker');
        if (ad_tracker_id) {
            ajax_wrapper(
                'POST',
                '/api/facebook_ad_tracker/',
                { id: ad_tracker_id, filing_status: 'File For Me' },
                console.log,
            );
        }

        window.location.href =
            '/file_for_me_sales/?savings=' +
            this.props.filing_options.max_value +
            '&cost=' +
            this.state.cost;
    }

    render() {
        let price = '20';

        if (this.state.page == 'payment') {
            return (
                <div style={{ padding: '10px' }}>
                    <h1 className="gradient-color">
                        ${price} Today, Guaranteed Savings Or Your Money Back.
                    </h1>
                    <p>
                        After paying, we'll be in touch over email to get any
                        additional info we need to file.
                    </p>
                    <Stripe price={price}></Stripe>
                </div>
            );
        } else if (this.state.page == 'last_chance') {
            return (
                <div style={{ padding: '10px' }}>
                    <h2>Last Chance</h2>
                    <p style={{ fontSize: '20px' }}>
                        Last chance, we will file for you at half off.{' '}
                        <strong>Just $10 for guaranteed tax savings.</strong>
                    </p>
                    <ul>
                        <li style={{ fontSize: '20px' }}>
                            Skip the hassle of paperwork and filings—we'll
                            prepare and submit everything on your behalf.
                        </li>
                        <li style={{ fontSize: '20px' }}>
                            No confusing and time confusing paperwork or
                            government office visits.
                        </li>
                        <li style={{ fontSize: '20px' }}>
                            <strong>100% money-back guarantee</strong> if your
                            application isn’t accepted.
                        </li>
                    </ul>

                    <Button
                        className="gradient-border"
                        onClick={this.file_for_me}
                        style={{ fontSize: '20px' }}
                    >
                        <div
                            className="gradient-border-content"
                            style={{ fontWeight: 500 }}
                        >
                            {'Ok! File For Me!'}
                        </div>
                    </Button>

                    <Button
                        type="dark"
                        onClick={this.diy}
                        style={{ fontSize: '20px', borderRadius: '100px' }}
                    >
                        {"No! I'll do the work myself."}
                    </Button>
                </div>
            );
        } else if (this.state.page == 'payment_preview') {
            return (
                <div style={{ padding: '10px' }}>
                    <h1 className="gradient-color">
                        We'd love to file for you!
                    </h1>
                    <h3>Next Steps</h3>
                    <ul style={{ fontSize: '25px' }}>
                        <li>
                            We're going to ask you for a couple extra pieces of
                            information we need to file.
                        </li>
                        <li>
                            We'll file the forms, sending to the right people,
                            at the right time, with the right info.
                        </li>
                        <li class="gradient-color">
                            We'll celebrate together when you start paying less
                            taxes!
                        </li>
                        <li>
                            <strong>No risk to you.</strong> Either you save
                            money or we give you a full refund.
                        </li>
                    </ul>

                    <Button
                        className="gradient-border"
                        onClick={() => this.setState({ page: 'payment' })}
                    >
                        <div
                            className="gradient-border-content"
                            style={{ fontWeight: 500, fontSize: '20px' }}
                        >
                            {'Pay My Filing Fee And Get My Tax Savings'}
                        </div>
                    </Button>
                </div>
            );
        } else if (this.state.page == 'thank_you') {
            return <ThankYou />;
        } else if (this.state.page == 'filing_options') {
            if (this.props.only_file_for_me) {
                return (
                    <FileForMe
                        price={price}
                        file_for_me={this.file_for_me}
                        disabled={this.props.disabled}
                    />
                );
            } else {
                return (
                    <div style={{ textAlign: 'center', padding: '20px' }}>
                        <h1
                            style={{ fontSize: '48px', fontWeight: 'bold' }}
                            className="gradient-color"
                        >
                            Filing For {this.props.filing_options.name}
                        </h1>
                        <p
                            style={{
                                padding: '20px',
                                display: 'inline-block',
                            }}
                        >
                            Would you rather us file for you? Or we can give you
                            the instructions for you to file everything on your
                            own.
                        </p>

                        <div className="row" style={{ textAlign: 'left' }}>
                            <div className="col-12 col-lg-6">
                                <FileForMe
                                    price={price}
                                    file_for_me={this.file_for_me}
                                    disabled={this.props.disabled}
                                />
                            </div>
                            <div className="col-12 col-lg-6">
                                <div
                                    className="simple-card"
                                    style={{
                                        padding: '20px',
                                        marginTop: '31px',
                                    }}
                                >
                                    <h4>Do It Yourself</h4>
                                    <div className="page-break"></div>
                                    <h1>$0</h1>
                                    <div className="page-break"></div>
                                    <ul>
                                        <li>
                                            We’ll provide you with the necessary
                                            forms and filing instructions for
                                            your application.
                                        </li>
                                        <li>
                                            On average, most homeowners spend
                                            2-3 hours completing the forms.
                                            Filing typically requires an
                                            in-person visit or mailing them in.
                                        </li>
                                        <li>
                                            For a hassle-free experience,
                                            consider letting us handle the
                                            process for you.
                                        </li>
                                    </ul>
                                    <Button
                                        onClick={() =>
                                            this.setState({
                                                page: 'last_chance',
                                                cost: 1000,
                                            })
                                        }
                                        type="dark"
                                        style={{ borderRadius: '100px' }}
                                    >
                                        File It Myself
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }
        }
    }
}
