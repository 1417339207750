import React, { Component } from 'react';
import { Button} from 'library';

import { DOLLAR_STYLING } from 'constants';

export default class ExemptionDisplay extends Component {
    constructor(props) {
        super(props);
        
        let open_requirements = false;
        if (this.props.open_requirements) {
            open_requirements = this.props.open_requirements;
        }

        this.state = {
            open_requirements: open_requirements,
        }
    }

    render() {
        let exemption = this.props.exemption;

        let qualify_button = <Button type="dark" style={{borderRadius:'10px'}} onClick={this.props.clear_form}>Re-enter my info.</Button>;
        if (this.props.qualify) {
            qualify_button = <Button
                className="gradient-border"
                onClick={() => this.props.toggle_filing_options({'name': exemption.name, 'id':exemption.id, 'max_value':exemption.max_value})}
            >
                <div
                    className="gradient-border-content"
                    style={{ fontWeight: 500 }}
                >
                    {'File My Exemption'}

                </div>
            </Button>

        }

        let requirements_display = <a style={{color:'blue', cursor:'pointer'}} onClick={() => this.setState({open_requirements: !this.state.open_requirements})}>See Requirements</a>
        if (this.state.open_requirements) {
            requirements_display = <div>
                <a style={{color:'blue', cursor:'pointer'}} onClick={() => this.setState({open_requirements: !this.state.open_requirements})}>Hide Requirements</a>
                <ul>{this.props.requirement_list}</ul>
            </div>
        }
        
        return (
            <div className="exemption-line-item">
                <div style={{float:'right'}}>
                    <strong style={{paddingRight:'10px', fontSize:'20px'}} className='gradient-color'>{exemption.max_value.toLocaleString(
                            'en-US',
                            DOLLAR_STYLING,
                        )}</strong>
                    {qualify_button}

                </div>
            
            <div className="label">
                <div className="text">
                    <h4>{exemption.name}</h4>
                    <div stlye={{display:'block'}}>{requirements_display}</div>
                </div>
            </div>
        </div>
        )
    }
}