import React, { Component } from 'react';
import { Container, Button } from 'library';
import { isMobile } from 'react-device-detect';
import { ajax_wrapper, bound_font_size } from 'functions';

import Navigation from './navigation';

export default class Overpaying extends Component {
    constructor(props) {
        super(props);
        this.click_cta = this.click_cta.bind(this);
    }

    click_cta() {
        let session_id = localStorage.getItem('session_id');
        if (session_id) {
            let event_data = {
                session_id: session_id,
                event_name: 'overpaying_cta',
                event_data: {},
            };
            ajax_wrapper('POST', '/api/create_event/', event_data, console.log);
        }
        let href = window.location.href;
        href = href.substring(0, href.indexOf(','));

        window.location.href = window.location.href + '#comparables';
    }

    render() {
        let navigation = <Navigation />;

        let header_font_size = bound_font_size(4.2, 80, 40);

        return (
            <div
                className="gradient-background-overpaying"
                style={{
                    marginBottom: '40px',
                    position: 'relative',
                    margin: '0px 10px',
                    borderRadius: '32px',
                }}
            >
                <Container
                    className="slide nav-wrapper"
                    min_height={true}
                    style={{
                        textAlign: 'center',
                        maxWidth: '100%',
                        paddingTop: '40px',
                    }}
                >
                    <div style={{}}>
                        <div
                            id="propertysearch"
                            style={{
                                fontSize: header_font_size + 'vw',
                                fontWeight: '500',
                                lineHeight: 'normal',
                            }}
                        >
                            <div>It Appears You Are...</div>
                            <div
                                className="gradient-color"
                                style={{ fontSize: '1.4em' }}
                            >
                                Overpaying ↓
                            </div>
                        </div>
                    </div>
                    <div style={{ margin: '10vh 0px 6vh' }}>
                        <h4
                            style={{
                                fontSize: header_font_size / 2 + 'vw',
                                display: 'inline-block',
                                margin: '0px 0px 15px',
                            }}
                        >
                            Now's the time to{' '}
                            <span className="gradient-color">Act</span> - Let's
                            start <span className="gradient-color">Saving</span>{' '}
                            you today!
                        </h4>
                        <br />
                        <Button
                            className="gradient-border"
                            onClick={this.click_cta}
                        >
                            <div
                                className="gradient-border-content"
                                style={{
                                    fontWeight: 500,
                                    fontSize: header_font_size / 3 + 'vw',
                                }}
                            >
                                {'File An Appeal'}
                            </div>
                        </Button>
                    </div>

                    <div
                        style={{
                            display: 'inline-block',
                            fontSize: header_font_size / 2 + 'vw',
                            width: '80%',
                            margin: '0px auto',
                        }}
                    >
                        <div
                            className="gradient-background-darker"
                            style={{
                                padding: '15px 30px',
                                borderRadius: '32px',
                            }}
                        >
                            {'Most of our customers have saved '}
                            <b style={{ color: '#5e50f7', fontSize: '1.2em' }}>
                                80%
                            </b>
                            {' more in our new tax exemptions section, '}
                            <a href="#exemptions" style={{ color: 'inherit' }}>
                                {'check it out!'}
                            </a>
                        </div>
                    </div>

                    {navigation}
                </Container>
            </div>
        );
    }
}
