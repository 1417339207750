import React, { Component } from 'react';
import { Container, Button, Form, TextInput} from 'library';
import { DOLLAR_STYLING } from 'constants';

export default class AdjustSquareFootage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            'appeal': false,
            pct:1,
        }

        this.submit_change = this.submit_change.bind(this);
        this.file_appeal = this.file_appeal.bind(this);
    }

    submit_change(data) {
        console.log("Submit Change", data)
        let property = this.props.property;
        let square_feet = property.propertyInfo.livingSquareFeet;
        let acreage = property.lotInfo.lotSquareFeet

        let square_feet_submitted = parseFloat(data['square_feet'])
        let acreage_submitted = parseFloat(data['acreage'])

        console.log("Square Feet", square_feet_submitted, square_feet, square_feet_submitted < square_feet)
        console.log("Acreage", acreage_submitted, acreage, acreage_submitted < acreage)

        if (square_feet_submitted < square_feet || acreage_submitted < acreage) {
            let square_feet_pct = square_feet_submitted/square_feet;
            let acreage_pct = acreage_submitted/acreage;
            let pct = acreage_pct;
            if (square_feet_pct < acreage_pct) {
                pct = square_feet_pct;
            }
            console.log("New State")
            this.setState({appeal: true, pct: pct, square_feet: square_feet, acreage: acreage})
        }
        else {
            console.log("New Square Footage")
            this.props.new_square_footage(data)
        }
    }

    file_appeal() {
        let property = this.props.property;
        let tax_amount = property.taxInfo.taxAmount;
        let savings = (tax_amount * (1 - this.state.pct));
        let name = 'Sqft Adjustment: (' + this.state.square_feet + ',' + this.state.acreage + ')'
        this.props.toggle_filing_options({'name': name, 'id':null, 'max_value':savings})
    }

    render() {
        let property = this.props.property;
        let square_feet = property.propertyInfo.livingSquareFeet;
        let acreage = property.lotInfo.lotSquareFeet;
        
        
        if (this.state.appeal) {
            let tax_amount = property.taxInfo.taxAmount;
            let savings = (tax_amount * (1 - this.state.pct)).toLocaleString('en-US', DOLLAR_STYLING);
            return (
                <Container className="slide" min_height={true}>
                    <div
                        style={{
                            textAlign: 'center',
                            padding: '20px 15px',
                        }}
                    >
                        <h1>Congratulations! You can appeal up to {savings} in yearly taxes.</h1>
                        <p style={{fontSize: '20px'}}>With the government listing the wrong square footage/acreage for your property, you have the right to correct them and appeal your taxes.</p>
                        <Button className='button-cta' style={{color:'white'}} onClick={this.file_appeal}>Help Me Appeal!</Button>
                    </div>
                </Container>
            )
        }
        else {
            return (
                <Container className="slide" min_height={true}>
                    <div className='row'>
                        <div className='col-md-4 col-sm-12'></div>
                        <div className='col-md-4 col-sm-12'>
                        <div
                            style={{
                                padding: '20px 15px',
                            }}
                        >
                            <h2>Help Us Fill In The Correct Data</h2>
                            <p>This will help us determine what appeals you can pursue to lower your taxes.</p>
                            <Form defaults={{'square_feet': square_feet, 'acreage': acreage}} submit={this.submit_change} submit_text={'Submit Changes'}>
                                <TextInput type='number' label={'Square Footage: (Currently: ' + square_feet + ')'} name='square_feet' />
                                <TextInput type='number' label={'Acreage In Sq. Ft.: (Currently: ' + acreage + ')'} name='acreage' />
                            </Form>

                        </div>

                        </div>
                        <div className='col-md-4 col-sm-12'></div>
                    </div>
                    
                </Container>
            );
        }
        

    }
}
