import { Container } from 'library';
import React, { Component } from 'react';

export default class AboutUs extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                <Container>
                    <h1
                        className="gradient-color"
                        style={{ textAlign: 'center', margin: '40px' }}
                    >
                        Ever Notice How Often the News Talk About the Challenges
                        of Homeownership in America?
                    </h1>
                    <p style={{ fontSize: '20px' }}>
                        Property taxes keep rising, mortgages are harder to
                        manage, and homeowners are left navigating a completely
                        broken system on their own. Every year, millions of
                        Americans overpay on{' '}
                        <strong>personal property tax</strong> simply because
                        they don’t know they’ve been over-assessed—or don’t have
                        the time to fight back.
                    </p>
                    <p style={{ fontSize: '20px' }}>
                        At <strong>HomeBase</strong>, we’re fixing this. We use{' '}
                        <strong>
                            best-in-class AI and quantitative models
                        </strong>{' '}
                        to detect inaccurate assessments and streamline the
                        appeal process, ensuring homeowners never pay more than
                        they should. Our platform helps homeowners understand
                        their <strong>taxes on property</strong>, identify
                        potential <strong>tax exemptions</strong>, and even
                        estimate fair tax rates with our{' '}
                        <strong>property tax calculator.</strong>
                    </p>

                    <div style={{ height: '50px' }}></div>
                    <div className="row">
                        <div className="col-md-6 col-sm-12">
                            <h1>
                                Our{' '}
                                <span className="gradient-color">Mission</span>
                            </h1>
                            <p style={{ fontSize: '20px' }}>
                                We help homeowners save Time and Money by fixing
                                a broken, outdated tax system—starting with
                                over-assessments.{' '}
                            </p>

                            <p style={{ fontSize: '20px' }}>
                                By combining technology, big data, and expert
                                insights, we make it easy for homeowners to get
                                back their hard-earned money.
                            </p>
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <img
                                src="/static/images/about_us_1.png"
                                style={{ maxWidth: '100%' }}
                            />
                        </div>
                    </div>
                    <div style={{ height: '20px' }}></div>
                    <div className="row">
                        <div className="col-md-6 col-sm-12">
                            <img
                                src="/static/images/about_us_2.png"
                                style={{ maxWidth: '100%' }}
                            />
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <h1>
                                Our{' '}
                                <span className="gradient-color">Vision</span>
                            </h1>
                            <p style={{ fontSize: '20px' }}>
                                We’re building the{' '}
                                <strong>homebase for home finances</strong>—a
                                central command center that helps American
                                homeowners optimize their biggest financial
                                asset: their home.{' '}
                            </p>
                            <p style={{ fontSize: '20px' }}>
                                <strong>Property taxes</strong> are just the
                                beginning. Our goal is to expand into mortgages,
                                insurance, and home equity, giving homeowners
                                the tools they need to maximize savings and
                                financial security.
                            </p>
                        </div>
                    </div>
                </Container>

                <div
                    className="gradient-background-light-center"
                    id="comparables"
                >
                    <Container className="slide">
                        <div className="slide-header">
                            <h4>The Problems We’re Solving</h4>
                            <h6>
                                Homeowners are losing billions each year due to:
                            </h6>
                        </div>
                        <div className="row" style={{ textAlign: 'center' }}>
                            <div
                                className="col-md-4 col-sm-12"
                                style={{ margin: '20px 0px' }}
                            >
                                <div className="simple-card numbered-card">
                                    <h4>Outdated Data</h4>
                                    <p>
                                        The system uses crappy and inaccurate
                                        property-to-property comparisons.
                                    </p>
                                    <img
                                        src="/static/images/problem_1.png"
                                        style={{ maxWidth: '100%' }}
                                    />
                                </div>
                            </div>
                            <div
                                className="col-md-4 col-sm-12"
                                style={{ margin: '20px 0px' }}
                            >
                                <div className="simple-card numbered-card">
                                    <h4>Oupaque Process</h4>
                                    <p>
                                        The system is intentionally confusing,
                                        bureaucratic, and time-consuming.
                                    </p>
                                    <img
                                        src="/static/images/problem_2.png"
                                        style={{ maxWidth: '100%' }}
                                    />
                                </div>
                            </div>
                            <div
                                className="col-md-4 col-sm-12"
                                style={{ margin: '20px 0px' }}
                            >
                                <div className="simple-card numbered-card">
                                    <h4>Limited Access</h4>
                                    <p>
                                        The system is not designed  to get you
                                        crucial data and insights,
                                        like qualification
                                        criteria for exemptions.
                                    </p>
                                    <img
                                        src="/static/images/problem_3.png"
                                        style={{ maxWidth: '100%' }}
                                    />
                                </div>
                            </div>
                        </div>
                    </Container>
                </div>

                <div
                    style={{
                        padding: '5px',
                        margin: '40px',
                        borderRadius: '20px',
                    }}
                    className="gradient-border"
                >
                    <div
                        style={{
                            backgroundColor: 'white',
                            padding: '40px',
                            borderRadius: '20px',
                        }}
                    >
                        <Container>
                            <h1>
                                Why{' '}
                                <span className="gradient-color">HomeBase</span>
                                ?
                            </h1>
                            <ul>
                                <li
                                    style={{ margin: '10px', fontSize: '20px' }}
                                >
                                    <strong>AI-Powered Savings</strong> –
                                    Best-in-class AI and quantitative models
                                    uncover tax savings opportunities that
                                    others miss.
                                </li>
                                <li
                                    style={{ margin: '10px', fontSize: '20px' }}
                                >
                                    <strong>Seamless Process</strong> – No
                                    confusing paperwork, no legal jargon. Most
                                    importantly, it’s free to try!{' '}
                                </li>
                                <li
                                    style={{ margin: '10px', fontSize: '20px' }}
                                >
                                    <strong>
                                        Built for Homeowners, By Homeowners
                                    </strong>{' '}
                                    – We advocate for you, not the tax
                                    authorities.
                                </li>
                            </ul>
                        </Container>
                    </div>
                </div>

                <Container>
                    <h1>
                        Our <span className="gradient-color">Team</span>
                    </h1>
                    <div style={{ textAlign: 'center' }}>
                        <p style={{ fontSize: '20px' }}>
                            We’re homeowners, and we’ve felt the frustration of
                            rising costs and complex tax systems first hand.
                        </p>
                        <p style={{ fontSize: '20px' }}>
                            <strong>
                                HomeBase was built by homeowners, for
                                homeowners.{' '}
                            </strong>
                        </p>
                        <p style={{ fontSize: '20px' }}>
                            Using our background in{' '}
                            <strong>
                                data, business dynamics, and automation,
                            </strong>{' '}
                            we’ve created a solution that’s{' '}
                            <strong>
                                more effective and affordable than hiring a
                                lawyer.
                            </strong>{' '}
                            Whether it’s analyzing{' '}
                            <strong>property-to-property</strong> tax trends,
                            identifying <strong>tax exemptions,</strong> or
                            providing a user-friendly property tax calculator,
                            HomeBase gives homeowners the necessary tools to{' '}
                            <strong>fight for a fair tax bill.</strong>
                        </p>
                    </div>
                </Container>
                <div style={{ textAlign: 'center' }}>
                    <img
                        src="/static/images/team.png"
                        style={{ maxWidth: '100%' }}
                    />
                </div>
            </div>
        );
    }
}
