import React, { Component } from 'react';
import { ajax_wrapper, get_global_state } from 'functions';
import { Loading, Container, Button } from 'library';
import { Helmet } from 'react-helmet';
import { DOLLAR_STYLING } from 'constants';
import {
    PrettyAddress,
    FAQs,
    SignUpModal,
    AddressSearch,
    ComparableMap,
} from 'components';
import { FilingOptions } from 'pages';

class CustomerReview extends Component {
    render() {
        let star_style = {
            margin: '4px',
        };
        return (
            <div
                className="simple-card"
                style={{
                    overflow: 'hidden',
                    margin: '0px',
                    textAlign: 'center',
                    height: '100%',
                }}
            >
                <div
                    style={{
                        fontSize: '1.8em',
                        padding: '5px',
                    }}
                >
                    <i class="bi bi-star-fill sienna" style={star_style}></i>
                    <i class="bi bi-star-fill sienna" style={star_style}></i>
                    <i class="bi bi-star-fill sienna" style={star_style}></i>
                    <i class="bi bi-star-fill sienna" style={star_style}></i>
                    <i class="bi bi-star-fill sienna" style={star_style}></i>
                </div>
                <div style={{ padding: '10px 0px' }}>
                    <h3>
                        {this.props.name} Saved {this.props.savings}
                    </h3>
                </div>
                <div>{this.props.text}</div>
            </div>
        );
    }
}

export default class MobileInvestorsLandingPage extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    componentDidMount() {
        let params = get_global_state()['params'];

        if (params['utm_source'] && !this.tracking) {
            this.tracking = true;

            let data = {
                utm_source: params['utm_source'],
                utm_medium: params['utm_medium'],
                utm_campaign: params['utm_campaign'],
                utm_content: params['utm_content'],
            };
            ajax_wrapper(
                'POST',
                '/api/facebook_ad_tracker/',
                data,
                this.save_ad_tracker,
            );
        }
    }

    save_ad_tracker = (data) => {
        console.log('Save Ad Tracker', data);
        localStorage.setItem('ad_tracker', data['id']);
    };

    render() {
        let header_font_size = 10;

        let slide_style = {
            textAlign: 'center',
            padding: '30px 20px',
        };

        return (
            <div>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Save Money On Your Property Taxes and Bills</title>
                    <meta
                        name="description"
                        content="An easy way to find where you are overpaying on property expenses."
                    />
                </Helmet>

                <div style={slide_style}>
                    <div>
                        <div
                            style={{
                                fontSize: header_font_size + 'vw',
                            }}
                        >
                            <div>
                                There’s a <b>FASTER</b>, <b>EASIER</b>, and{' '}
                                <b>SMARTER</b> Way to start saving on your
                                taxes.
                            </div>
                        </div>
                    </div>
                    <div style={{ padding: '15px' }}>
                        At HomeBase we fight back for you using best-in-class AI
                        and data models to identify tax savings the system
                        doesn’t want you to find.Then, we handle everything for
                        you—so you can get your money back without the
                        headaches.
                    </div>

                    <div>
                        <Button className="button-blue-cta">
                            {'YES! HELP ME SAVE'}
                        </Button>
                    </div>
                </div>

                <div className="blue-background" style={slide_style}>
                    <CustomerReview
                        name={'Karen'}
                        savings={'$400'}
                        text={
                            'Homebase helped me find and apply for $400/yr in exemptions per year. Thank you!!'
                        }
                    />
                </div>

                <div style={slide_style}>
                    <div
                        style={{
                            fontSize: header_font_size / 2 + 'vw',
                        }}
                    >
                        You CAN Appeal Your Property Taxes Yourself
                    </div>
                    <div
                        style={{
                            fontSize: header_font_size + 'vw',
                        }}
                    >
                        <b>But Why Would You?</b>
                    </div>

                    <div
                        className="gradient-border"
                        style={{ borderRadius: '16px' }}
                    >
                        <div
                            className="gradient-border-content"
                            style={{ borderRadius: '16px' }}
                        >
                            <div
                                style={{
                                    fontSize: header_font_size / 2 + 'vw',
                                }}
                            >
                                Your property tax bill is probably too high, and
                                you have every right to{' '}
                                <b className="blue-text">fight back</b>. But
                                here’s the catch:
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    className="gradient-background-light"
                    style={slide_style}
                ></div>

                <div
                    className="gradient-background-darker"
                    style={slide_style}
                ></div>

                <div></div>

                <div className="gradient-background-light"></div>

                <FAQs />

                <SignUpModal
                    property={{}}
                    close={() => this.setState({ property: {} })}
                />
            </div>
        );
    }
}
