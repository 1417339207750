import React, { Component } from 'react';

import { ajax_wrapper, get_global_state } from 'functions';
import { Form, TextInput, Container, Alert, TextArea } from 'library';

class ContactUs extends Component {
    constructor(props) {
        super(props);

        let data = {}
        if (props.subject) {
            data['subject'] = props.subject;
        }

        if (window.secret_react_state['user']) {
            data['email'] = window.secret_react_state['user']['email'];
        }

        this.state = {
            data: data,
            sent: false,
        };

        this.contact_us = this.contact_us.bind(this);
        this.contact_us_callback = this.contact_us_callback.bind(this);
    }

    contact_us(data) {

        let email_data = {
            email: data['email'],
            subject: data['subject'],
            message: data['message'],
        };
        email_data.email = email_data.email.toLowerCase();

        let params = get_global_state()['params'];
        if (params['property_id']) {
            email_data['property_id'] = params['property_id']
        }

        ajax_wrapper('POST', '/api/contact_us/', email_data, this.contact_us_callback);
    }

    contact_us_callback(state) {
        this.setState({ sent: true });
    }

    render() {
        let sent = null;
        if (this.state.sent) {
            sent = <Alert type="success">We've sent your message and will get back to you as soon as possible.</Alert>;
        }

        return (
            <div style={{ padding: '20px', textAlign:'left' }}>
                <h3>Get In Touch With HomeBase</h3>
                <Form submit={this.contact_us} submit_text="Submit" defaults={this.state.data}>
                    <TextInput
                        name="email"
                        label="Email"
                        required={true}
                    />
                    <TextInput
                        name="subject"
                        label="Subject"
                        required={true}
                    />
                    <TextArea
                        name="message"
                        label="Message"
                        required={true}
                    />
                    
                </Form>
                {sent}
            </div>
        );
    }
}

export default ContactUs;
