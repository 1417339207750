import React, { Component } from 'react';
import { ajax_wrapper, get_global_state } from 'functions';
import { Loading, Container, Button, Form, TextInput, Alert } from 'library';

class FilingRequest extends Component {
    render() {
        let request = this.props.request;
        let property = request.property_dict;

        let email = request.email;
        if (request.user && request.user != "None") {
            email = request.user.email;
        }

        let exemption = ''
        if (request.appeal) {
            exemption = 'Property Tax Appeal'
        }
        else if (request.exemption) {
            exemption = request.exemption.name
        }

        let address = {'label':'NA', 'state':'NA'}
        if (property && property.propertyInfo && property.propertyInfo.address) {
            address = property.propertyInfo.address;
        }

        let diy = "False"
        if (request.diy) {
            diy = "True"
        }

        return (
            <tr>
                <td>{email}</td>
                <td>{address.label}</td>
                <td>{address.state}</td>
                <td>{exemption}</td>
                <td>{diy}</td>
            </tr>
        )
    }
}

export default class RequestList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            filing_requests: [],
            loaded: false,
        };

        this.get_requests = this.get_requests.bind(this);
        this.load_requests = this.load_requests.bind(this);
    }

    componentDidMount() {
        this.get_requests();
    }

    get_requests() {
        ajax_wrapper('GET','/api/get_request_list/', {}, this.load_requests)
        
    }

    load_requests(data) {
        this.setState({filing_requests: data['request_list'], loaded:true})
    }



    render() {

        let request_list = []
        for (let request of this.state.filing_requests) {
            request_list.push(<FilingRequest request={request} />)
        }

        return (
            <Container>

                <Loading loaded={this.state.loaded}>

                        <h1>Filing Requests</h1>
                        <table className='table'>
                            <thead>
                                <tr>
                                    <th>Email</th>
                                    <th>Address</th>
                                    <th>State</th>
                                    <th>Exemption</th>
                                    <th>DIY</th>
                                </tr>
                            </thead>
                            <tbody>
                                {request_list}
                            </tbody>
                        </table>
                </Loading>
            </Container>
        );
    }
}
