import React, { Component } from 'react';

export default class Navigation extends Component {
    render() {
        return (
            <div className="nav nav-container">
                <div
                    className="row nav-fill"
                    style={{ margin: '0px', width: '100%' }}
                >
                    <div class="col-md-3 col-12 nav-item">
                        <a
                            class="nav-link"
                            href="#property"
                            style={{
                                color: 'black',
                                textDecoration: 'underline',
                            }}
                        >
                            Property Info
                        </a>
                    </div>
                    <div class="col-md-3 col-12 nav-item">
                        <a
                            class="nav-link"
                            href="#comparables"
                            style={{
                                color: 'black',
                                textDecoration: 'underline',
                            }}
                        >
                            Comparables
                        </a>
                    </div>
                    <div class="col-md-3 col-12 nav-item">
                        <a
                            class="nav-link"
                            href="#exemptions"
                            style={{
                                color: 'black',
                                textDecoration: 'underline',
                            }}
                        >
                            Exemptions
                        </a>
                    </div>
                    <div class="col-md-3 col-12 nav-item">
                        <a
                            class="nav-link"
                            href="#insurance"
                            style={{
                                color: 'black',
                                textDecoration: 'underline',
                            }}
                        >
                            Insurance And Mortgages
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}
