import { Container } from 'library';
import React, { Component } from 'react';
import { get_global_state } from 'functions';
import { DOLLAR_STYLING } from 'constants';
import { Review } from 'components';
import { Button } from 'library';

export default class Home extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        let params = get_global_state()['params'];
        let savings_param = params['savings'];
        let cost_param = params['cost']

        let header_text = 'There’s a FASTER, EASIER, and SMARTER Way to start saving on your taxes.'
        let cta = 'Help Me Save!'
        if (parseFloat(savings_param) > 0) {
            let savings = parseInt(savings_param).toLocaleString('en-US', DOLLAR_STYLING);
            header_text = 'There’s a FASTER, EASIER, and SMARTER Way to start saving ' + savings + ' on your taxes.'
            cta = 'Help Me Save ' + savings + '!'
        }

        return (
            <div>
            <Container>
                <h1 className='gradient-color' style={{textAlign:'center', margin:'40px'}}>{header_text}</h1>
                <p style={{fontSize:'20px'}}>At HomeBase we fight back for you using best-in-class
                    AI and data models to identify tax savings the system
                    doesn’t want you to find.Then, we handle everything for
                    you—so you can get your money back without the
                    headaches.
                </p>

                <div style={{textAlign:'center', margin:'40px'}}>
                    <Button className='button-cta' style={{color:'white'}} href={'/checkout_page?savings=' + savings_param + '&cost=' + cost_param}>Yes! Help Me Save!</Button>
                </div>

                <div style={{height:'50px'}}></div>
            </Container>
                
                
            <div className="gradient-background-light-center" id='comparables'>
                <Container className="slide">
                    <div className='row'>
                        <Review 
                            name = 'Russ'
                            review = "Ian at Homebase personally made sure I was taken care of throughout the filing process."
                        />
                        <Review 
                            name = 'Alex'
                            review = "I found out about my appeal after the deadline. HomeBase was able to navigate the process and gave me a discount for the inconvenience. Wow!"
                        />
                        <Review 
                            name = 'Deborah'
                            review = "I had tried for file for an exemption on my own and was denied. HomeBase was able to help me navigate the process to apply the right way."
                        />
                    </div>
                </Container>
            </div>
                
            <div style={{margin:'40px 0px', textAlign:'center'}}>
            <Container>
                <h1>You CAN Appeal Your Property Taxes Yourself... But Why Would You?</h1>
                <p style={{fontSize:'25px'}}>Every Year, Homeowners Overpay Thousands in Property Taxes, But YOU Don’t Have To Be One Of Them!</p>

                <div className='row' style={{margin:'40px 0px'}}>
                    <div className='col-md-6 col-sm-12' style={{textAlign:'left'}}>
                        <h3>
                            Your property tax bill is probably too high,
                            and you have every right to fight back. But
                            here’s the catch:
                        </h3>
                        <ul>
                            <li style={{fontSize:'20px'}}>
                                The system is designed to be difficult. Government
                                offices bury homeowners in complex forms, legal
                                jargon, and endless waiting.
                            </li>
                            <li style={{fontSize:'20px'}}>
                                The process takes weeks (or months). If you file
                                incorrectly or miss a deadline, you’re stuck overpaying
                                for another year.
                            </li>
                            <li style={{fontSize:'20px'}}>
                                Your local assessor isn’t on your side. Their job is to
                                collect taxes—not to help you pay less.
                            </li>
                        </ul>
                        <h3>
                            Sure, you can do this on your own... but is it
                            really worth your time and frustration?
                        </h3>
                    </div>
                    <div className='col-md-6 col-sm-12'>
                        <img src='/static/images/pulling_hair.png' style={{width:'100%'}} />
                    </div>

                    <h1 style={{marginTop:'20px'}}>
                        There’s a Faster, Easier, and Smarter
                        Way to Win Your Appeal!
                    </h1>
                    <p style={{fontSize:'20px'}}>
                        For just $20, we take all the hassle off your plate and handle
                        everything for you—so you can start saving without lifting a
                        finger!
                    </p>

                    <div style={{textAlign:'center', margin:'40px'}}>
                        <Button className='button-cta' style={{color:'white'}} href={'/checkout_page?savings=' + savings_param + '&cost=' + cost_param}>Yes! Help Me Save!</Button>
                    </div>

                    <p style={{fontSize:'20px'}}>The filing deadline is approaching quickly! Don’t wait!</p>
                </div>
            </Container>
            </div>

            <div style={{backgroundColor:'black', color:'white', textAlign:'center'}}>
                <Container className="slide">
                    <h1>
                        <i class="bi bi-shield" style={{marginRight:'10px'}}></i>
                        We Take Your Data Security Seriously
                    </h1>
                    <p style={{fontSize:'20px'}}>
                        We take your privacy seriously. We use industry-leading
                        encryption to protect your data and are BBB-accredited,
                        ensuring the highest standards of security and trust.
                    </p>
                    <div className='col-12' style={{textAlign: 'center', marginTop: '20px'}}>
                        <img style={{height:'60px'}} src="/static/images/bbb.svg" />
                    </div>
                </Container>
            </div>

            <div style={{backgroundColor:'white', color:'black', textAlign:'center', margin:'40px 0px'}}>
                <Container className="slide">
                    <h1>Why HomeBase?</h1>
                    <div className='row' style={{margin:'40px 0px'}}>
                        <div className='col-md-4 col-sm-12'>
                            <div style={{backgroundColor: '#BF77F6', marginBottom:'20px', borderRadius: '10px', boxShadow: '0 4px 6px rgba(0,0,0,0.1)', padding: '20px', maxWidth: '400px', textAlign: 'center'}}>
                                <h2 style={{color: 'white'}}>FASTER</h2>
                                <p style={{color: 'white', fontSize:'20px'}}>
                                    We file everything correctly and efficiently so you
                                    don’t waste time tracking down paperwork.
                                </p>
                            </div>
                        </div>
                        <div className='col-md-4 col-sm-12'>
                            <div style={{backgroundColor: '#BF77F6', marginBottom:'20px', borderRadius: '10px', boxShadow: '0 4px 6px rgba(0,0,0,0.1)', padding: '20px', maxWidth: '400px', textAlign: 'center'}}>
                                <h2 style={{color: 'white'}}>EASIER</h2>
                                <p style={{color: 'white', fontSize:'20px'}}>
                                    No forms, no
                                    waiting on hold, no
                                    legal confusion—
                                    we handle the
                                    entire process for
                                    you.
                                </p>
                            </div>
                        </div>
                        <div className='col-md-4 col-sm-12'>
                            <div style={{backgroundColor: '#BF77F6', marginBottom:'20px', borderRadius: '10px', boxShadow: '0 4px 6px rgba(0,0,0,0.1)', padding: '20px', maxWidth: '400px', textAlign: 'center'}}>
                                <h2 style={{color: 'white'}}>SMARTER</h2>
                                <p style={{color: 'white', fontSize:'20px'}}>
                                    Our AI-powered
                                    technology and
                                    data models find
                                    savings most
                                    homeowners miss.
                                </p>
                            </div>
                        </div>
                    </div>

                    <h1>
                        And the best part? If we don’t get you savings, you get
                        100% of your money back—no risk, all reward.
                    </h1>

                    <div className='row'>
                        <div className='col-md-6 col-sm-12' style={{textAlign:'left', margin:'40px 0px'}}>
                            <h3>What Happens If You Try to Appeal on Your Own?</h3>
                            <p style={{fontSize:'20px'}}><strong>The DIY Nightmare: What You’re Up Against</strong></p>
                            <ul>
                                <li style={{fontSize:'20px'}}>
                                    Step 1: Research the process – Every county has
                                    different rules, deadlines, and required
                                    documents. Miss one detail, and your appeal
                                    could be denied.
                                </li>
                                <li style={{fontSize:'20px'}}>
                                    Step 2: Gather the right documents – Tax
                                    records, property deeds, exemption
                                    paperwork... where do you even start?
                                </li>
                                <li style={{fontSize:'20px'}}>
                                    Step 3: Fill out government forms – Get ready
                                    for confusing paperwork, legal terms, and
                                    requirements that aren’t clearly explained.
                                </li>
                                <li style={{fontSize:'20px'}}>
                                    Step 4: File everything before the deadline –
                                    One mistake, and you’re out of luck until next
                                    year.
                                </li>
                                <li style={{fontSize:'20px'}}>
                                    Step 5: Wait (and hope) – Your local office is in
                                    no rush to process your request. If there’s an
                                    issue, they won’t call you to fix it.
                                </li>
                            </ul>
                            <p style={{fontSize:'20px'}}><strong>Sound Frustrating? It is.</strong></p>
                        </div>
                        <div className='col-md-6 col-sm-12'>
                            <img src='/static/images/paperwork.png' style={{width:'100%'}} />
                        </div>

                        <div style={{backgroundColor:'black', color:'white', padding:'20px', margin:'20px'}}>
                            <h1>
                                Or, You Could Let Us Do It All For You.
                                For just $20, you can avoid all the red tape and let
                                HomeBase handle everything from start to finish.
                            </h1>
                        </div>
                    </div>
                </Container>
            </div>

            <div className="gradient-background-light-center" id='comparables'>
                <Container className="slide">
                    <div className='gradient-border' style={{padding:'5px'}}>
                        <div style={{backgroundColor:'white', padding:'20px', textAlign:'center', borderRadius:'95px'}}>
                            <h1>How It Works:</h1>
                            <p style={{fontSize:'20px'}}>
                                Appeal deadlines are fast approaching—if you don’t file
                                now, you’ll be stuck overpaying for another year!
                            </p>
                            <h1>As Easy As...</h1>
                            <div style={{textAlign:'left', margin:'0px 10%'}}>
                                <p style={{fontSize:'20px'}}>
                                    <strong>1. You Sign Up in Minutes</strong> – Pay just $20
                                    and let us get to work.
                                </p>
                                <p style={{fontSize:'20px'}}>
                                    <strong>2. We Do the Hard Work</strong> – We prepare,
                                    file, and submit everything on your behalf.
                                </p>
                                <p style={{fontSize:'20px'}}>
                                    <strong>3. You Start Saving</strong> — Or Your Money Back –
                                    If we don’t win your appeal, we refund you
                                    100%.
                                </p>
                            </div>
                            

                            <div style={{textAlign:'center', margin:'40px'}}>
                                <Button className='button-cta' style={{color:'white'}} href={'/checkout_page?savings=' + savings_param + '&cost=' + cost_param}>Yes! Help Me Save!</Button>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>

            <Container>
                <h1 style={{margin:'40px 0px'}}>Frequently Asked Questions (FAQ):</h1>
                <p>
                    <strong>Why should I appeal my property taxes?</strong>
                </p>
                <p>
                    Many homeowners are overpaying on property taxes due to outdated or inaccurate assessments.
                    By filing an appeal, you can potentially save $1,000+ per year—but only if you act before the
                    deadline.
                </p>

                <p>
                    <strong>Can I file an appeal myself?</strong>
                </p>
                <p>
                    Yes, but the process is time-consuming, confusing, and full of legal jargon. You’d need to gather
                    the right documents, complete complex forms, and navigate government red tape—all before the
                    appeal deadline.With HomeBase, we handle everything for just $20, so you don’t have to waste
                    time or stress over paperwork.
                </p>

                <p>
                    <strong>What happens after I pay the $20?</strong>
                </p>
                <p>
                    Once you pay, we take care of everything for you:
                </p>
                <ol>
                    <li>
                        We’ll reach out via email to confirm your details and any necessary documents.
                    </li>
                    <li>
                        We prepare and submit your appeal directly to your local tax assessor’s office.
                    </li>
                    <li>
                        You’ll receive a confirmation with estimated response times.
                    </li>
                    <li>
                        If approved, you start saving immediately!
                    </li>
                </ol>
                <p>
                    And if for any reason we can’t get your taxes reduced, we refund your payment 100%—no risk, no hassle.
                </p>

                <p>
                    <strong>How much money can I save?</strong>
                </p>
                <p>
                    Our customers save an average of $100s per year—many save $2,000 to $4,000 annually. The
                    best part? These savings continue every year until you move.
                </p>

                <p>
                    <strong>What happens if my appeal is denied?</strong>
                </p>
                <p>
                    We stand by our service with a 100% money-back guarantee. If we don’t secure savings for you,
                    you get your $20 back—no questions asked.
                </p>

                <p>
                    <strong>When is the deadline to file?</strong>
                </p>
                <p>
                    Appeal deadlines vary by state, but they’re coming up fast! If you don’t file in time, you’ll be locked
                    into overpaying for another year.
                </p>

                <p>
                    <strong>What documents will I need?</strong>
                </p>
                <p>
                    For the free review, you would need to provide your local government’s assessment document. If
                    you use our filing service, we’ll help you gather everything needed, which may include: Driver’s
                    license, Property deed, Tax assessment letter, etc.
                </p>

                <p>
                    <strong>Is my information safe?</strong>
                </p>
                <p>
                    Yes! We take your privacy seriously. We use industry-leading encryption to protect your data and
                    are BBB-accredited, ensuring the highest standards of security and trust.
                </p>

                <p>
                    <strong>Why is this so cheap?</strong>
                </p>
                <p>
                    Lawyers charge hundreds or even thousands to appeal property taxes. We’ve automated and
                    streamlined the processusing AI and expert-driven data, allowing us to offer the same service for
                    just $20.We believe homeowners shouldn’t have to overpay just because the system is broken—
                    and we’re here to fix it.
                </p>

                <div style={{textAlign:'center', margin:'40px'}}>
                    <Button className='button-cta' style={{color:'white'}} href={'/checkout_page?savings=' + savings_param  + '&cost=' + cost_param}>Yes! Help Me Save!</Button>
                </div>
            </Container>
                
            </div>
        );
    }
}
