
import React, { Component } from 'react';
import {Alert, Container} from 'library';
import {PaymentStatus} from 'pages';

export default class ThankYouForFiling extends Component {

    render() {

        return(
            <Container>
            <div style={{ textAlign: 'center', minHeight:'800px' }}>
                <Alert type='success'><PaymentStatus /></Alert>
                <h1
                    style={{ fontSize: '48px', fontWeight: 'bold' }}
                    className="gradient-color"
                >
                    Thanks for choosing HomeBase.
                </h1>
                <h5>
                    We'll be in touch over email with the next steps
                    to help you save on your property taxes.
                </h5>
            </div>
            </Container>
        );
    }
}