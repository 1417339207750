import {
    AdminRoutes,
    AuthedRoutes,
    AnonymousRoutes,
    Login,
    Logout,
    LoadLastURL,
    SignUp,
    GoogleLogin,
    AIBlogPost,
    ViewFormSubmissions,
    FunnelPerformance,
    LandingPage,
    PropertyOpportunities,
    CreateExemption,
    ExemptionList,
    ElderlyExemption,
    RequestList,
    PaymentThankYou,
    Stripe,
    AboutUs,
    CheckoutPage,
    FileForMeSales,
    PropertyOpportunityGuide,
    InvestorsLandingPage,
    PrivacyPolicy,
    TermsOfService,
    PropertyInfo,
    Applications,
} from 'pages';

const routes = [
    {
        path: 'logout',
        element: <Logout />,
    },
    {
        path: 'login',
        element: <Login />,
    },

    {
        path: 'load_last_url',
        element: <LoadLastURL />,
    },
    {
        path: '',
        element: <AnonymousRoutes />,
        children: [
            {
                path: 'google_login',
                element: <GoogleLogin />,
            },
            {
                path: '',
                element: <InvestorsLandingPage />,
            },
            {
                path: 'sign_up',
                element: <SignUp />,
            },
            {
                path: 'property_tax_guides/:state/:county',
                element: <AIBlogPost />,
            },
            {
                path: 'property_tax_guides/:state/:county/:city',
                element: <AIBlogPost />,
            },
            {
                path: '/address_search',
                element: <InvestorsLandingPage />,
            },
            {
                path: '/property_opportunities/:property_id',
                element: <PropertyOpportunities />,
            },
            {
                path: '/property_opportunity_guide/:property_id',
                element: <PropertyOpportunityGuide />,
            },
            {
                path: '/elderly_exemption',
                element: <ElderlyExemption />,
            },
            {
                path: '/stripe',
                element: <Stripe />,
            },
            {
                path: '/complete_payment',
                element: <PaymentThankYou />,
            },
            {
                path: '/about_us',
                element: <AboutUs />,
            },
            {
                path: '/investors',
                element: <InvestorsLandingPage />,
            },
            {
                path: '/checkout_page',
                element: <CheckoutPage />,
            },
            {
                path: '/file_for_me_sales',
                element: <FileForMeSales />,
            },
            {
                path: '/privacy_policy',
                element: <PrivacyPolicy />,
            },
            {
                path: '/terms_of_service',
                element: <TermsOfService />,
            },
            {
                path: '/property_info/:property_id',
                element: <PropertyInfo />,
            },
            
        ],
    },
    {
        path: '',
        element: <AuthedRoutes />,
        children: [],
    },
    {
        path: 'admin',
        element: <AdminRoutes />,
        children: [
            {
                path: 'form_submissions',
                element: <ViewFormSubmissions />,
            },
            {
                path: 'funnel_performance',
                element: <FunnelPerformance />,
            },
            {
                path: 'exemption_list',
                element: <ExemptionList />,
            },
            {
                path: 'create_exemption/:id',
                element: <CreateExemption />,
            },
            {
                path: 'create_exemption',
                element: <CreateExemption />,
            },
            {
                path: 'request_list',
                element: <RequestList />,
            },
            {
                path: 'applications',
                element: <Applications />,
            },
        ],
    },
];

export default routes;
