import React, { Component } from 'react';

import { ajax_wrapper, get_global_state, format_date } from 'functions';
import { DOLLAR_STYLING } from 'constants';
import { PrettyAddress, UnlockButton } from 'components';
import { Button, Modal } from 'library';
import { ContactUs } from 'pages';

export default class PropertyCheck extends Component {
    constructor(props) {
        super(props);

        this.state = { contact_us: false };

        this.toggle_contact_us = this.toggle_contact_us.bind(this);
        this.to_dollar_string = this.to_dollar_string.bind(this);
        this.to_number_string = this.to_number_string.bind(this);
    }

    toggle_contact_us() {
        this.setState({ contact_us: !this.state.contact_us });
    }

    to_dollar_string(value, value_on_error) {
        try {
            if (value == null) {
                return value_on_error
            }
            else {
                return (parseFloat(value).toLocaleString(
                    'en-US',
                    DOLLAR_STYLING,
                ))
            }
            
        }
        catch {
            return value_on_error
        }
    }

    to_number_string(value, value_on_error) {
        if (value == null) {
            return value_on_error
        }
        else {
            try {
                return (parseFloat(value).toLocaleString(
                    'en-US',
                ))
            }
            catch {
                return value_on_error
            }
        }
    }

    render() {
        let property = this.props.property;

        let image_size = 'col-12 col-lg-7';
        let text_size = 'col-12 col-lg-5';
        if (this.props.small) {
            image_size = 'col-12';
            text_size = 'col-12';
        }

        let latlng =
            property.propertyInfo.latitude +
            ',' +
            property.propertyInfo.longitude;

        let img_url =
            'https://maps.googleapis.com/maps/api/streetview?location=' +
            latlng +
            '&size=600x400&key=AIzaSyCTeyTeeIoM-qDCe1Z8W7OK0qDVAJ8QzhU';


        let realtor = null;
        if (
            property.mlsHistory.length > 0 &&
            property.mlsHistory[0].agentName
        ) {
            realtor = [
                <div className="line-item inline">
                    <label>Realtor:</label>
                    <span>{property.mlsHistory[0].agentName}</span>
                </div>,
                <div className="line-item inline">
                    <label>Realtor Email:</label>
                    <span>{property.mlsHistory[0].agentEmail}</span>
                </div>,
            ];
        }

        let owner_details = null;
        if (
            window.secret_react_state.user &&
            window.secret_react_state.user.id
        ) {
            owner_details = (
                <div className="line-item">
                    <label>Owner Details</label>
                    <span>
                        Owner 1: {property.ownerInfo.owner1FullName}, Owner 2:{' '}
                        {property.ownerInfo.owner2FullName}
                    </span>
                </div>
            );
        }

        let sold_on = 'N/A';
        if (property.lastSaleDate) {
            sold_on = new Date(property.lastSaleDate).toLocaleString('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
            });
        }

        return (
            <div
                className="simple-card"
                style={{ padding: '20px', marginBottom: '80px' }}
            >
                
                <div className="row">
                    <div
                        className={image_size}
                        style={{ marginBottom: '10px' }}
                    >
                        <div
                            className="background-image"
                            style={{
                                backgroundImage: `url(${img_url})`,
                                borderRadius: '6px',
                                backgroundPosition: 'center',
                                height: '100%',
                            }}
                        ></div>
                    </div>
                    <div className={text_size} style={{ marginBottom: '10px', fontSize:'25px' }}>
                        <div>
                            <PrettyAddress
                                property={property.propertyInfo.address}
                            />

                            <div className="grey-header">Property Details</div>

                            <div className="line-item">
                                <label>Living:</label>
                                <span>
                                    {this.to_number_string(property.propertyInfo.livingSquareFeet, 'NA')} Sq.
                                    Ft.
                                </span>
                            </div>
                            <div className="line-item">
                                <label>Acreage:</label>
                                <span>
                                    {this.to_number_string(property.lotInfo.lotSquareFeet,'NA')} Sq. Ft.
                                </span>
                            </div>

                            {owner_details}

                            <div className="page-break"></div>

                            <div style={{ textAlign: 'center' }}>
                                <h3>Are the square footage and acreage accurate?</h3>
                                <br />
                                <div className='row'>
                                    <div className='col-6'>
                                        <Button
                                            type="danger"
                                            style={{ fontSize:'25px' }}
                                            onClick={() => this.props.set_page('adjust_square_footage')}
                                        >
                                            No
                                        </Button>
                                    </div>
                                    
                                    <div className='col-6'>
                                        <Button
                                            style={{
                                                fontSize:'25px',
                                            }}
                                            type='success'
                                            onClick={() => this.props.set_page('under_over')}
                                        >
                                            Yes
                                        </Button>
                                    </div>
                                </div>

                                <Modal
                                    style={{ maxWidth: '600px' }}
                                    show={this.state.contact_us}
                                    onHide={this.toggle_contact_us}
                                >
                                    <ContactUs
                                        subject={
                                            'Issue With Property: ' +
                                            this.props.property.propertyInfo
                                                .address.label
                                        }
                                    />
                                </Modal>
                            </div>
                        </div>
                    </div>
                    
                    
                </div>
            </div>
        );
    }
}
