import React, { Component } from 'react';
import { ajax_wrapper, get_global_state } from 'functions';
import { Loading, Container, Button, Form, TextInput, Alert } from 'library';

class ExemptionRow extends Component {
    render() {
        return (
            <tr>
                <td>{this.props.exemption.name}</td>
                <td>{this.props.exemption.state}</td>
                <td><a href={'/admin/create_exemption/' + this.props.exemption.id}>Edit</a></td>
            </tr>
        )
    }
}

export default class ExemptionList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            property_tax_exemptions: [],
            loaded: false,
        };

        this.get_exemptions = this.get_exemptions.bind(this);
        this.load_exemptions = this.load_exemptions.bind(this);
    }

    componentDidMount() {
        this.get_exemptions();
    }

    get_exemptions() {
        ajax_wrapper('GET','/api/home/propertytaxexemption/list/', {}, this.load_exemptions)
        
    }

    load_exemptions(data) {
        this.setState({property_tax_exemptions: data, loaded:true})
    }



    render() {

        let exemption_list = []
        for (let exemption of this.state.property_tax_exemptions) {
            exemption_list.push(<ExemptionRow exemption={exemption} />)
        }

        return (
            <Container>

                <Loading loaded={this.state.loaded}>

                        <h1>Property Tax Exemptions</h1>
                        <Button type='primary' href='/admin/create_exemption/'>Create New Exemption</Button>
                        <table className='table'>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>State</th>
                                    <th>Edit</th>
                                </tr>
                            </thead>
                            <tbody>
                                {exemption_list}
                            </tbody>
                        </table>
                </Loading>
            </Container>
        );
    }
}
