import React, { Component } from 'react';
import { ajax_wrapper, get_global_state } from 'functions';
import { Loading, Container, Button, Form, TextInput, Alert, Modal } from 'library';
import {Helmet} from "react-helmet";
import { DOLLAR_STYLING } from 'constants';
import {FilingOptions} from 'pages';
import { ExemptionDisplay, Review } from 'components';

export default class ElderlyExemptions extends Component {
    constructor(props) {
        super(props);

        this.state = {
            exemptions: [],
            property: {},
            loaded: false,
            state: '',
            filing_options: {},
            open_filing_options: false,
        };

        this.load_exemptions = this.load_exemptions.bind(this);
        this.get_state = this.get_state.bind(this);
        this.toggle_filing_options = this.toggle_filing_options.bind(this);
        this.login_callback = this.login_callback.bind(this);
    }

    componentDidMount() {

        let params = get_global_state()['params'];
        ajax_wrapper('GET','/api/elderly_exemptions/', {'state': this.get_state(params['zip'])}, this.load_exemptions)
        this.get_state(params['zip'])
        
        if (params['email']) {
            let email = decodeURI(params['email']);
            email = email.replace('%40', '@');
        
            let data = {
                email: email,
                password: 'lowermytaxes',
            };
            data.email = data.email.toLowerCase();
            ajax_wrapper('POST', '/user/register/', data, this.login_callback);
        };

    }

    login_callback = (value) => {
        if ('error' in value) {
            if (value.error === 'Invalid Credentials') {
                this.setState({
                    error: (
                        <p>
                            Wrong Email or Password. If this is your first time
                            logging in, you may need to{' '}
                            <a href="/password_reset_request/">
                                reset your password first.
                            </a>
                        </p>
                    ),
                });
            } else {
                this.setState({ error: value.error });
            }
        } else {
            localStorage.setItem('token', value.access);
            localStorage.setItem('refresh_token', value.refresh);
            localStorage.setItem('token_time', new Date());
        }
    };



    get_state(zip) {

        /* Ensure param is a string to prevent unpredictable parsing results */
        if (typeof zip !== 'string') {
            console.error('Must pass the zipcode as a string.');
            return;
        }
      
        /* Ensure we have exactly 5 characters to parse */
        if (zip.length !== 5) {
            console.error('Must pass a 5-digit zipcode.');
            return;
        }
      
        /* Ensure we don't parse strings starting with 0 as octal values */
        const zipcode = parseInt(zip, 10);
      
        let st;
        let state;
      
        /* Code cases alphabetized by state */
        if (zipcode >= 35000 && zipcode <= 36999) {
            st = 'AL';
            state = 'Alabama';
        } else if (zipcode >= 99500 && zipcode <= 99999) {
            st = 'AK';
            state = 'Alaska';
        } else if (zipcode >= 85000 && zipcode <= 86999) {
            st = 'AZ';
            state = 'Arizona';
        } else if (zipcode >= 71600 && zipcode <= 72999) {
            st = 'AR';
            state = 'Arkansas';
        } else if (zipcode >= 90000 && zipcode <= 96699) {
            st = 'CA';
            state = 'California';
        } else if (zipcode >= 80000 && zipcode <= 81999) {
            st = 'CO';
            state = 'Colorado';
        } else if ((zipcode >= 6000 && zipcode <= 6389) || (zipcode >= 6391 && zipcode <= 6999)) {
            st = 'CT';
            state = 'Connecticut';
        } else if (zipcode >= 19700 && zipcode <= 19999) {
            st = 'DE';
            state = 'Delaware';
        } else if (zipcode >= 32000 && zipcode <= 34999) {
            st = 'FL';
            state = 'Florida';
        } else if ( (zipcode >= 30000 && zipcode <= 31999) || (zipcode >= 39800 && zipcode <= 39999) ) {
            st = 'GA';
            state = 'Georgia';
        } else if (zipcode >= 96700 && zipcode <= 96999) {
            st = 'HI';
            state = 'Hawaii';
        } else if (zipcode >= 83200 && zipcode <= 83999 && zipcode != 83414) {
            st = 'ID';
            state = 'Idaho';
        } else if (zipcode >= 60000 && zipcode <= 62999) {
            st = 'IL';
            state = 'Illinois';
        } else if (zipcode >= 46000 && zipcode <= 47999) {
            st = 'IN';
            state = 'Indiana';
        } else if (zipcode >= 50000 && zipcode <= 52999) {
            st = 'IA';
            state = 'Iowa';
        } else if (zipcode >= 66000 && zipcode <= 67999) {
            st = 'KS';
            state = 'Kansas';
        } else if (zipcode >= 40000 && zipcode <= 42999) {
            st = 'KY';
            state = 'Kentucky';
        } else if (zipcode >= 70000 && zipcode <= 71599) {
            st = 'LA';
            state = 'Louisiana';
        } else if (zipcode >= 3900 && zipcode <= 4999) {
            st = 'ME';
            state = 'Maine';
        } else if (zipcode >= 20600 && zipcode <= 21999) {
            st = 'MD';
            state = 'Maryland';
        } else if ( (zipcode >= 1000 && zipcode <= 2799) || (zipcode == 5501) || (zipcode == 5544 ) ) {
            st = 'MA';
            state = 'Massachusetts';
        } else if (zipcode >= 48000 && zipcode <= 49999) {
            st = 'MI';
            state = 'Michigan';
        } else if (zipcode >= 55000 && zipcode <= 56899) {
            st = 'MN';
            state = 'Minnesota';
        } else if (zipcode >= 38600 && zipcode <= 39999) {
            st = 'MS';
            state = 'Mississippi';
        } else if (zipcode >= 63000 && zipcode <= 65999) {
            st = 'MO';
            state = 'Missouri';
        } else if (zipcode >= 59000 && zipcode <= 59999) {
            st = 'MT';
            state = 'Montana';
        } else if (zipcode >= 27000 && zipcode <= 28999) {
            st = 'NC';
            state = 'North Carolina';
        } else if (zipcode >= 58000 && zipcode <= 58999) {
            st = 'ND';
            state = 'North Dakota';
        } else if (zipcode >= 68000 && zipcode <= 69999) {
            st = 'NE';
            state = 'Nebraska';
        } else if (zipcode >= 88900 && zipcode <= 89999) {
            st = 'NV';
            state = 'Nevada';
        } else if (zipcode >= 3000 && zipcode <= 3899) {
            st = 'NH';
            state = 'New Hampshire';
        } else if (zipcode >= 7000 && zipcode <= 8999) {
            st = 'NJ';
            state = 'New Jersey';
        } else if (zipcode >= 87000 && zipcode <= 88499) {
            st = 'NM';
            state = 'New Mexico';
        } else if ( (zipcode >= 10000 && zipcode <= 14999) || (zipcode == 6390) || (zipcode == 501) || (zipcode == 544) ) {
            st = 'NY';
            state = 'New York';
        } else if (zipcode >= 43000 && zipcode <= 45999) {
            st = 'OH';
            state = 'Ohio';
        } else if ((zipcode >= 73000 && zipcode <= 73199) || (zipcode >= 73400 && zipcode <= 74999) ) {
            st = 'OK';
            state = 'Oklahoma';
        } else if (zipcode >= 97000 && zipcode <= 97999) {
            st = 'OR';
            state = 'Oregon';
        } else if (zipcode >= 15000 && zipcode <= 19699) {
            st = 'PA';
            state = 'Pennsylvania';
        } else if (zipcode >= 300 && zipcode <= 999) {
            st = 'PR';
            state = 'Puerto Rico';
        } else if (zipcode >= 2800 && zipcode <= 2999) {
            st = 'RI';
            state = 'Rhode Island';
        } else if (zipcode >= 29000 && zipcode <= 29999) {
            st = 'SC';
            state = 'South Carolina';
        } else if (zipcode >= 57000 && zipcode <= 57999) {
            st = 'SD';
            state = 'South Dakota';
        } else if (zipcode >= 37000 && zipcode <= 38599) {
            st = 'TN';
            state = 'Tennessee';
        } else if ( (zipcode >= 75000 && zipcode <= 79999) || (zipcode >= 73301 && zipcode <= 73399) ||  (zipcode >= 88500 && zipcode <= 88599) ) {
            st = 'TX';
            state = 'Texas';
        } else if (zipcode >= 84000 && zipcode <= 84999) {
            st = 'UT';
            state = 'Utah';
        } else if (zipcode >= 5000 && zipcode <= 5999) {
            st = 'VT';
            state = 'Vermont';
        } else if ( (zipcode >= 20100 && zipcode <= 20199) || (zipcode >= 22000 && zipcode <= 24699) || (zipcode == 20598) ) {
            st = 'VA';
            state = 'Virginia';
        } else if ( (zipcode >= 20000 && zipcode <= 20099) || (zipcode >= 20200 && zipcode <= 20599) || (zipcode >= 56900 && zipcode <= 56999) ) {
            st = 'DC';
            state = 'Washington DC';
        } else if (zipcode >= 98000 && zipcode <= 99499) {
            st = 'WA';
            state = 'Washington';
        } else if (zipcode >= 24700 && zipcode <= 26999) {
            st = 'WV';
            state = 'West Virginia';
        } else if (zipcode >= 53000 && zipcode <= 54999) {
            st = 'WI';
            state = 'Wisconsin';
        } else if ( (zipcode >= 82000 && zipcode <= 83199) || zipcode == 83414 ) {
            st = 'WY';
            state = 'Wyoming';
        } else {
            st = 'none';
            state = 'none';
            console.log('No state found matching', zipcode);
        }
      
        /* Return `state` for full name or `st` for postal abbreviation */
        let params = get_global_state()['params']
        let address = params['address']
        let property = {'propertyInfo': {'address': {'label': address, 'state': st}}}
        this.setState({state: state, property: property});
        return st;
      }

    load_exemptions(data) { 
        this.setState({exemptions: data['exemptions'], loaded: true})
    }

    toggle_filing_options(exemption) {
        this.setState({ filing_options: exemption, open_filing_options: true });
    };

    render() {
        
        let exemption_list = []
        for (let exemption of this.state.exemptions) {
            let requirement_list = [];
            for (let req of exemption.requirements_list) {
                if (req.type == 'Age Requirement') {
                    requirement_list.push(
                        <li>
                            You or your spouse are over the age of {req.value}.
                        </li>,
                    );
                } else if (req.type == 'Income Requirement') {
                    requirement_list.push(
                        <li>Your household income is below {req.value.toLocaleString(
                            'en-US',
                            DOLLAR_STYLING,
                        )}.</li>,
                    );
                } else if (req.type == 'Disability Requirement') {
                    requirement_list.push(
                        <li>
                            You or your spouse have been determined to be
                            disabled under the rules of the Social Security
                            Administration
                        </li>,
                    );
                } else if (req.type == 'Veteran Requirement') {
                    requirement_list.push(
                        <li>
                            You or your spouse are an active or honorably
                            discharded veteran of the United States Armed
                            Forces.
                        </li>,
                    );
                } else if (req.type == 'Blind Requirement') {
                    requirement_list.push(
                        <li>
                            You or your spouse have been determined to be blind
                            under the rules of the Social Security
                            Administration
                        </li>,
                    );
                } else if (req.type == 'Widow Requirement') {
                    requirement_list.push(
                        <li>
                            You are the surviving spouse of a deceased person
                            who was receiving an exemption at the time of death.
                        </li>,
                    );
                } else if (req.type == 'Home Ownership Requirement') {
                    requirement_list.push(
                        <li>
                            You have owned and lived in your house for{' '}
                            {req.value} years.
                        </li>,
                    );
                }
            }
            exemption_list.push(
                <ExemptionDisplay open_requirements={true} requirement_list={requirement_list} exemption={exemption} qualify={true} toggle_filing_options={this.toggle_filing_options} />
            )
        }

        let qualify =  `${this.state.state} has the following exemptions you may qualify for:`
        if (exemption_list.length === 0) {
            qualify = "Unfortunately, you do not qualify for any exemptions at this time."
        }

        let width = window.screen.width * .8;
        if (width > 800) {
            width = 800;
        }
        let height = 3/4 * width;

        return (
            <Container>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Save Money On Your Property Taxes and Bills</title>
                    <meta name="description" content="An easy way to find where you are overpaying on property expenses." />
                </Helmet>

                <Loading loaded={this.state.loaded}>

                <Modal
                    show={this.state.open_filing_options}
                    style={{
                        maxWidth: '800px',
                        padding: '2px',
                        overflow: 'hidden',
                    }}
                    onHide={() =>
                        this.setState({
                            filing_options: {},
                            open_filing_options: false,
                        })
                    }
                >
                    
                    <FilingOptions property={this.state.property} filing_options={this.state.filing_options} close={() => this.setState({filing_options: {}, open_filing_options: false})}/>
                </Modal>

                <div style={{backgroundColor: "#f8f9fa",
                minHeight: "100vh",
                padding: "40px 20px"
                }}>

                <div style={{
                    maxWidth: "1000px",
                    margin: "100px auto",
                    position: "relative"
                }}>
                   
                        <h2  style={{textAlign:'center'}}>Your exemptions are below. First, we wanted to share a win!</h2>
                         <h2 style={{textAlign:'center'}}>We've helped people find <span className='gradient-color'>$1M in unfiled exemptions.</span> Check out a couple of their reviews</h2>
                            <div className='row'>
                                <Review 
                                    name = 'Karen T'
                                    review = "Homebase helped me find and apply for $400/yr in exemptions per year. Thank you!!"
                                    image = "https://img.freepik.com/free-photo/older-woman-outdoors-nature-with-laptop_23-2148740710.jpg"
                                />
                                <Review 
                                    name = 'Ahad S'
                                    review = "My family home was overpaying by $1000s of dollars. HomeBase helped me see that and figure out how to appeal."
                                    image = "https://media.licdn.com/dms/image/v2/C4E03AQGZcp_x9k8kiA/profile-displayphoto-shrink_800_800/profile-displayphoto-shrink_800_800/0/1566400183578?e=1744243200&v=beta&t=zMwl4wXmE1gA8CSBXwyOkPSCYpqDXT_-2IsZmio0wEU"
                                />
                                <Review 
                                    name = 'Chuck G'
                                    review = "I found out about HomeBase on facebook and just a day later, they had applied for $370 of exemptions every year for me. Thank you!"
                                    image = "https://www.westkentuckystar.com/getattachment/df633bc2-a266-40bb-b219-b93a58982121/GxbcufJ2Q0uYAXoEmF9vTA.jpg?lang=en-US&width=422&height=488&ext=.jpg"
                                />
                            </div>
                        </div>

                         {/* Main Container */}
                    <div style={{
                    backgroundColor: "white",
                    borderRadius: "16px",
                    boxShadow: "0 10px 30px rgba(0,0,0,0.08)",
                    overflow: "hidden"
                    }}>
                    {/* Header */}
                        <div style={{
                            background: "linear-gradient(135deg, #4a90e2 0%, #357abd 100%)",
                            padding: "30px",
                            color: "white"
                        }}>
                            <h1 style={{
                            fontSize: "28px",
                            margin: 0,
                            fontWeight: 600,
                            letterSpacing: "-0.5px"
                            }}>
                            {qualify}
                            </h1>
                        </div>

                        <div style={{ padding: "30px" }}>
                             {exemption_list}
                        </div>
                            
                            
                    </div>
                        
                    </div>
               
                </Loading>
            </Container>
        );
    }
}
