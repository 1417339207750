import React, { Component } from 'react';
import { ajax_wrapper } from 'functions';
import { Form, TextInput } from 'library';

class AddressPicker extends Component {
    constructor(props) {
        super(props);

        this.state = {
            backgroundColor: 'white',
            textColor: '#666',
        };
    }

    render() {
        let property = this.props.property;

        let style = { cursor: 'pointer', padding: '5px' };

        if (property.address == 'None Found') {
            return (
                <div className="google-address-result" style={style}>
                    No properties found. Which means you're paying the right
                    amount for your property tax! Keep it that way with instant
                    assessment alerts. <a href="/sign_up">Sign up today</a>
                </div>
            );
        } else {
            let display_address = property.address;

            return (
                <div
                    onClick={() =>
                        this.props.select_property(this.props.property)
                    }
                    className="black-font google-address-result"
                    style={style}
                >
                    {display_address}
                </div>
            );
        }
    }
}

export default class AddressSearch extends Component {
    constructor(props) {
        super(props);

        this.state = {
            potential_addresses: [],
            show_potential_address: true,
        };
    }

    search_address = (name, data) => {
        window.dataLayer.push({
            event: 'search_address',
        });

        let session_id = localStorage.getItem('session_id');
        if (session_id) {
            let unique_data = data;
            let event_data = {
                session_id: session_id,
                event_name: 'search_address',
                event_data: unique_data,
            };
            ajax_wrapper('POST', '/api/create_event/', event_data, console.log);
        }

        if (data['address'] && data['address'].length > 2) {
            ajax_wrapper(
                'GET',
                '/api/address_search/',
                data,
                this.load_addresses,
            );
        }
    };

    load_addresses = (data) => {
        console.log('Load Addresses', data['data']);
        this.setState({ potential_addresses: data['data'] });
    };

    focus_input = () => {
        this.setState({ show_potential_address: true });
    };

    blur_input = () => {
        //this.setState({show_potential_address: false})
    };

    render() {
        let addresses = [];
        if (this.state.show_potential_address) {
            //console.log("Potential Addresses", this.state.potential_addresses)
            for (let property of this.state.potential_addresses) {
                addresses.push(
                    <AddressPicker
                        property={property}
                        select_property={this.props.select_property}
                    />,
                );
            }
        }

        return (
            <Form
                auto_set_global_state={true}
                set_global_state={this.search_address}
                global_state_name="address_search"
            >
                <div className={this.props.input_class}>
                    <div style={{ position: 'relative' }}>
                        <TextInput
                            style={{
                                borderRadius: '100px',
                                borderColor: '#212529',
                                fontSize: '20px',
                                border: 'none',
                                padding: '20px 20px 20px 52px',
                            }}
                            name="address"
                            autocomplete={'off'}
                            placeholder="Search Your Address"
                        />
                        <div
                            style={{
                                position: 'absolute',
                                top: '21px',
                                left: '28px',
                                fontSize: '20px',
                                color: 'black',
                            }}
                        >
                            <i class="bi bi-geo-alt"></i>
                        </div>
                    </div>
                </div>

                <div style={{ position: 'relative' }}>
                    <div className="google-address-result-container">
                        {addresses}
                    </div>
                </div>
            </Form>
        );
    }
}
