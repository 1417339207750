export default function bound_font_size(scale, max_pixels, min_pixels) {
    let effective_size = window.innerWidth * (scale / 100);
    if (effective_size > max_pixels) {
        scale = (max_pixels * 100) / window.innerWidth;
    } else if (effective_size < min_pixels) {
        scale = (min_pixels * 100) / window.innerWidth;
    }

    return scale;
}
