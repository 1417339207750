import React, { Component } from 'react';
import { DOLLAR_STYLING } from 'constants';
import { Container, Button} from 'library';
import { isMobile } from 'react-device-detect';

export default class UnderOver extends Component {
    constructor(props) {
        super(props);
    }

    render() {

        let header_font_size = 84;
        if (isMobile) {
            header_font_size = 42;
        }
        let average_savings = this.props.average_savings;
        
        if (!average_savings || average_savings <= 0) {
            return (
                <div
                    className="hero-section gradient-background-underpaying"
                    style={{
                        marginBottom: '40px',
                        position: 'relative',
                    }}
                >
                    <Container min_height={true}>
                        <div
                            className="slide"
                            style={{
                                textAlign: 'center',
                            }}
                        >
                            <div
                                id="propertysearch"
                                style={{
                                    fontSize: header_font_size + 'px',
                                    fontWeight: '500',
                                    lineHeight: 'normal',
                                }}
                            >
                                <div>Congrats! You are...</div>
                                <div className="gradient-color">Underpaying ↓</div>
                            </div>
    
                            <h4
                                style={{
                                    fontSize: header_font_size / 2 + 'px',
                                    display: 'inline-block',
                                    margin: '15% 0px 10px',
                                }}
                            >
                                However, you can save{' '}
                                <span class="gradient-color">hundreds</span> more.{' '}
                                <span class="gradient-color">Click the Button</span>{' '}
                                to see how!
                            </h4>
                            <br />
                            <Button
                            style={{color:'white'}}
                            className="button-cta"
                            onClick={() => this.props.set_page('exemptions')}
                            >
                                    Show Me How I Can Save!
                            </Button>
                        </div>
                    </Container>
                </div>
            );
        }
        else {
            return (
                <div
                    className="gradient-background-overpaying"
                    style={{
                        marginBottom: '40px',
                        position: 'relative',
                        margin: '0px 10px',
                        borderRadius: '32px',
                    }}
                >
                    <Container className="slide" min_height={true}>
                        <div
                            style={{
                                textAlign: 'center',
                                padding: '20px 15px',
                            }}
                        >
                            <div
                                id="propertysearch"
                                style={{
                                    fontSize: header_font_size + 'px',
                                    fontWeight: '500',
                                    lineHeight: 'normal',
                                }}
                            >
                                <div>It Appears You Are...</div>
                                <div className="gradient-color">Overpaying by {parseFloat(this.props.average_savings).toLocaleString('en-US', DOLLAR_STYLING)} ↓</div>
                            </div>
    
                            <h4
                                style={{
                                    fontSize: header_font_size / 2 + 'px',
                                    display: 'inline-block',
                                    margin: '15% 0px 10px',
                                }}
                            >
                                Now's the time to{' '}
                                <span className="gradient-color">Act</span> - Let's
                                start <span className="gradient-color">Saving</span>{' '}
                                you today!
                            </h4>
                            <br />
                            <Button className="button-cta" onClick={() => this.props.set_page('comparables')}
                                style={{color:'white'}}
                                >
                                    Show Me The Data!
                            </Button>
                        </div>
                    </Container>
                </div>
            );
        }

    }
}
