import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { DOLLAR_STYLING } from 'constants';
import { ajax_wrapper, get_global_state } from 'functions';

import { Loading, Container, Button, Modal } from 'library';
import { Exemptions, Comparables,  } from 'components';
import {PropertyCheck, UnderOver, FilingOptions, AdjustSquareFootage} from 'pages';

export default class PropertyOpportunities extends Component {
    constructor(props) {
        super(props);

        this.state = {
            property: {},
            exemptions: [],
            comparables: [],
            loaded: false,
            qualify_for_exemptions: null,
            error: null,
            filing: false,
            open_filing_options: false,
            filing_options: {},
            page: 'property_check',
        };

        this.get_property = this.get_property.bind(this);
        this.load_property = this.load_property.bind(this);
        this.submit_info = this.submit_info.bind(this);
        this.toggle_filing_options = this.toggle_filing_options.bind(this);
        this.set_page = this.set_page.bind(this);
        this.new_square_footage = this.new_square_footage.bind(this);
    }

    componentDidMount() {
        this.get_property();
    }

    set_page(page) { 
        this.setState({page: page});
        window.scrollTo(0,0);
        if (page == 'other') {
            if (!this.initializedAds) {
                setTimeout(() => {
                    window.initializeMyFinance(true);
                    this.initializedAds = true;
                }, 500);
            }
        }
    }

    get_property = () => {
        let params = get_global_state()['params'];
        localStorage.setItem('property_id', params['property_id']);
        ajax_wrapper(
            'GET',
            '/api/property_opportunities/',
            { property_id: params['property_id'] },
            this.load_property,
        );
    };

    new_square_footage(data) {
        let params = get_global_state()['params'];
        localStorage.setItem('property_id', params['property_id']);
        ajax_wrapper(
            'GET',
            '/api/property_opportunities/',
            { property_id: params['property_id'], square_feet: data['square_feet'], acreage: data['acreage'] },
            this.load_property,
        );
    }

    load_property = (data) => {
        data['loaded'] = true;
        this.setState(data);

        let comparables = data.comparables;
        let average_savings = 0;
        let i = 0;
        let params = get_global_state()['params'];
        let property_id = params['property_id']

        let session_id = localStorage.getItem('session_id');
        if (session_id) {
            let event_data = {'session_id': session_id, 'property_id': property_id}
            ajax_wrapper('POST', '/api/create_session/', event_data, console.log);
        }

        if (comparables) {
            if (comparables.length == 0) {
                window.dataLayer.push({
                    'property_id': property_id,
                    'average_savings': 0,
                    'event': 'no_comps'
                  });

                  let session_id = localStorage.getItem('session_id');
                    if (session_id) {
                        let unique_data = {'property_id': property_id, 'average_savings': 0}
                        let event_data = {'session_id': session_id, 'event_name': 'no_comps', 'event_data': unique_data}
                        ajax_wrapper('POST', '/api/create_event/', event_data, console.log);
                    }
            }
            else {
                for (let comp of comparables) {
                    average_savings += comp.savings;
                    i++;
                    if (i == 3) {
                        break;
                    }
                }
                if (i != 0) {
                    average_savings = average_savings / i;
                    if (average_savings <= 0) {
                        window.dataLayer.push({
                            'property_id': property_id,
                            'average_savings': average_savings,
                            'event': 'negative_savings'
                          });

                          let session_id = localStorage.getItem('session_id');
                            if (session_id) {
                                let unique_data = {'property_id': property_id, 'average_savings': average_savings}
                                let event_data = {'session_id': session_id, 'event_name': 'negative_savings', 'event_data': unique_data}
                                ajax_wrapper('POST', '/api/create_event/', event_data, console.log);
                            }
                    } else {
                        window.dataLayer.push({
                            'property_id': property_id,
                            'average_savings': average_savings,
                            'event': 'positive_savings'
                          });

                          let session_id = localStorage.getItem('session_id');
                            if (session_id) {
                                let unique_data = {'property_id': property_id, 'average_savings': average_savings}
                                let event_data = {'session_id': session_id, 'event_name': 'positive_savings', 'event_data': unique_data}
                                ajax_wrapper('POST', '/api/create_event/', event_data, console.log);
                            }
                    }
                }
            }
        }
    }

    submit_info = (data) => {
        ajax_wrapper(
            'POST',
            '/user/submit_demographics/',
            { data: data },
            console.log,
        );
        this.setState({ qualify_for_exemptions: data });

        window.dataLayer.push({
            'event': 'submit_exemption_info',
        });

        let session_id = localStorage.getItem('session_id');
        if (session_id) {
            let unique_data = data
            let event_data = {'session_id': session_id, 'event_name': 'submit_exemption_info', 'event_data': unique_data}
            ajax_wrapper('POST', '/api/create_event/', event_data, console.log);
        }
    };

    toggle_filing_options(exemption) {
        this.setState({ filing_options: exemption, open_filing_options: true });
        window.dataLayer.push({
        'event': 'show_filing_options',
        'exemption_name': exemption.name,
        });

        let session_id = localStorage.getItem('session_id');
        if (session_id) {
            let unique_data = exemption
            let event_data = {'session_id': session_id, 'event_name': 'show_filing_options', 'event_data': unique_data}
            ajax_wrapper('POST', '/api/create_event/', event_data, console.log);
        }
    };

    render() {
        if (this.state.error) {
            return (
                <Container>
                    <p>
                        We're sorry. The analysis we do requires a bit more
                        information. We'll follow up with you in an email
                        shortly.
                    </p>
                </Container>
            );
        }

        if (this.state.loaded) {
            //console.log("State", this.state)
            let property = this.state.property;
            let property_id = property.id;
            //console.log("Property", property)
            let address = property.propertyInfo.address;

            let comparables = this.state.comparables;
            let average_savings = 0;
            let i = 0;
            let savings = (
                <span>
                    No Current Comparables, we'll be in touch when we found
                    something.
                </span>
            );

            for (let comp of comparables) {
                average_savings += comp.savings;
                i++;
                if (i == 3) {
                    break;
                }
            }
            if (i != 0) {
                average_savings = average_savings / i;
                if (average_savings <= 0) {
                    savings = (
                        <span>
                            $0, we'll follow up when there is an opportunity.
                            Check below for other ways to save.
                        </span>
                    );
                } else {
                    savings = (
                        <span>
                            {average_savings.toLocaleString(
                                'en-US',
                                DOLLAR_STYLING,
                            )}
                        </span>
                    );
                }
            }

            let filing = <Modal
                show={this.state.open_filing_options}
                style={{
                    maxWidth: '800px',
                    padding: '2px',
                    overflow: 'hidden',
                }}
                onHide={() =>
                    this.setState({
                        filing_options: {},
                        open_filing_options: false,
                    })
                }
            >
                <FilingOptions property={property} filing_options={this.state.filing_options} close={() => this.setState({filing_options: {}, open_filing_options: false})}/>
            </Modal>

            if (this.state.page == 'under_over') {
                return (<UnderOver property={property} set_page={this.set_page} average_savings={average_savings} />)
            }
            else if (this.state.page == 'property_check') {
                return (
                    <Container>
                        <PropertyCheck average_savings={average_savings} property={property} set_page={this.set_page} savings={savings} />
                        {filing}
                    </Container>
                )
            }
            else if (this.state.page == 'adjust_square_footage') {
                return (
                <Container>
                    <AdjustSquareFootage property={property} new_square_footage={this.new_square_footage} toggle_filing_options={this.toggle_filing_options} />
                    {filing}
                </Container>
                )
            }
            else if (this.state.page == 'comparables') {
                return <Container>
                    <Comparables
                    property={property}
                    comparables={this.state.comparables}
                    want_to_file={this.toggle_filing_options}
                    average_savings={average_savings}
                />
                {filing}
                </Container>
            }
            else if (this.state.page == 'exemptions') {
                return <Container>
                    <Exemptions
                    toggle_filing_options={this.toggle_filing_options}
                    property={this.state.property}
                    exemptions={this.state.exemptions}
                    set_page={this.set_page}
                />
                {filing}
                </Container>
            }
            else if (this.state.page == 'other') {
                return (
                    <Container className="slide">
                        <div className="slide-header" id='insurance'>
                            <h4>Other Ways To Save</h4>
                            <h6>
                                The following are other ways to save money
                                on your yearly home expenses.
                            </h6>
                        </div>
                        <div
                            className="simple-card"
                            style={{ padding: '10px', marginTop: '20px' }}
                            id="mortgages"
                        >
                            <h6>Mortgage</h6>
                            <div>
                                Below, there are other mortgage offers that
                                you could re-finance and potentially save
                                $100s per month depending on your current
                                mortgage. Check them out.
                            </div>
                            <div
                                class="myFinance-widget"
                                data-ad-id="16d38958-a765-4eed-bb76-88443c3204c0"
                                data-campaign="homebase-home-equity-multi"
                                data-sub-id={property_id}
                            ></div>
                        </div>

                        <div
                            className="simple-card"
                            style={{ padding: '10px', marginTop: '20px' }}
                        >
                            <h6>Insurance</h6>
                            <div>
                                Another cause of high home costs is
                                insurance. Check and see if you can get a
                                better rate today.
                            </div>
                            <div
                                class="myFinance-widget"
                                data-ad-id="13647411-2e7e-45bc-829d-ce6630321fe6"
                                data-campaign="homebase-home-insurance-multi"
                                data-sub-id={property_id}
                            ></div>
                        </div>
                    </Container>
                )
            }
            else {
                return (
                    <p>Something went wrong: {this.state.page}</p>
                )
            }
        } else {
            return <Loading loaded={this.state.loaded} />;
        }
    }
}
