import React, { Component } from 'react';
import { ajax_wrapper, get_global_state, bound_font_size } from 'functions';
import { Loading, Container } from 'library';
import { Helmet } from 'react-helmet';
import { DOLLAR_STYLING } from 'constants';
import {
    PrettyAddress,
    FAQs,
    SignUpModal,
    AddressSearch,
    ComparableMap,
    MobileInvestorsLandingPage,
} from 'components';
import { FilingOptions } from 'pages';
import { isMobile } from 'react-device-detect';
import CountUp from "react-countup";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

class NewsArticle extends Component {
    render() {
        return (
            <div
                style={{
                    background: '#403E4B',
                    boxShadow: '0px 0px 10px rgba(0,0,0,.2)',
                    padding: '15px',
                    borderRadius: '16px',
                    height: '100%',
                }}
            >
                <div
                    style={{
                        borderBottom: 'thin solid #dfdfdf',
                        textAlign: 'center',
                    }}
                >
                    <img
                        src={this.props.logo}
                        style={{ padding: '10px 0px', width: 'auto' }}
                    />
                </div>
                <div style={{ padding: '10px 0px' }}>
                    <a
                        style={{ color: 'white' }}
                        href={this.props.link}
                        target="_blank"
                    >
                        {this.props.text}
                    </a>
                </div>
            </div>
        );
    }
}

class CustomerReview extends Component {
    render() {
        let star_style = {
            margin: '4px',
        };
        return (
            <div
                className="simple-card"
                style={{
                    overflow: 'hidden',
                    margin: '0px',
                    textAlign: 'center',
                    height: '100%',
                }}
            >
                <div
                    style={{
                        fontSize: '1.8em',
                        padding: '5px',
                    }}
                >
                    <i class="bi bi-star-fill sienna" style={star_style}></i>
                    <i class="bi bi-star-fill sienna" style={star_style}></i>
                    <i class="bi bi-star-fill sienna" style={star_style}></i>
                    <i class="bi bi-star-fill sienna" style={star_style}></i>
                    <i class="bi bi-star-fill sienna" style={star_style}></i>
                </div>
                <div style={{ padding: '10px 0px' }}>
                    <h3>
                        {this.props.name} Saved {this.props.savings}
                    </h3>
                </div>
                <div>{this.props.text}</div>
            </div>
        );
    }
}

class ComparableRow extends Component {
    render() {
        let comparable = this.props.comparable;

        let latlng = comparable.latitude + ',' + comparable.longitude;

        let img_url =
            'https://maps.googleapis.com/maps/api/streetview?location=' +
            latlng +
            '&size=600x400&key=AIzaSyCTeyTeeIoM-qDCe1Z8W7OK0qDVAJ8QzhU';

        let savings = comparable.savings;

        let savings_type = <span>save</span>;
        if (savings < 0) {
            savings_type = <strong style={{ color: 'red' }}>lose</strong>;
            savings = Math.abs(savings);
        }

        return (
            <div
                className="simple-card"
                style={{ overflow: 'hidden', margin: '0px' }}
            >
                <a style={{ textDecoration: 'none' }}>
                    <div
                        className="background-image"
                        style={{
                            backgroundImage: `url(${img_url})`,
                            backgroundPosition: 'center',
                            margin: '-10px -20px 0px',
                            width: 'calc(100% + 40px)',
                        }}
                    ></div>

                    <PrettyAddress
                        property={comparable.property_dict.propertyInfo.address}
                        small={true}
                    />
                </a>
                <div className="page-break"></div>

                <div style={{ textAlign: 'center' }}>
                    <h5>
                        This property is overpaying by{' '}
                        <span className="gradient-color">
                            {savings.toLocaleString('en-US', DOLLAR_STYLING)}
                        </span>{' '}
                        per year.
                    </h5>
                </div>
            </div>
        );
    }
}

class ExampleHomes extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { homes } = this.props;
    

        return (
            <Carousel
                additionalTransfrom={0}
                arrows={false}
                autoPlay
                autoPlaySpeed={3000}
                centerMode={false}
                className=""
                containerClass="container-with-dots"
                dotListClass=""
                draggable
                focusOnSelect={false}
                infinite
                itemClass=""
                keyBoardControl
                minimumTouchDrag={80}
                pauseOnHover
                renderArrowsWhenDisabled={false}
                renderButtonGroupOutside={false}
                renderDotsOutside={false}
                responsive={{
                    desktop: {
                    breakpoint: {
                        max: 3000,
                        min: 1024
                    },
                    items: 3,
                    partialVisibilityGutter: 40
                    },
                    mobile: {
                    breakpoint: {
                        max: 464,
                        min: 0
                    },
                    items: 1,
                    partialVisibilityGutter: 30
                    },
                    tablet: {
                    breakpoint: {
                        max: 1024,
                        min: 464
                    },
                    items: 2,
                    partialVisibilityGutter: 30
                    }
                }}
                rewind={false}
                rewindWithAnimation={false}
                rtl={false}
                shouldResetAutoplay
                showDots={true}
                sliderClass=""
                slidesToSlide={2}
                swipeable
                >
                {homes.map((home, index) => (
                    <div
                        
                        style={{ margin: '20px 20px' }}
                    >
                        <ComparableRow comparable={home} />
                    </div>
                ))}
            </Carousel>
        );
    }
}


export default class InvestorsLandingPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loaded: false,
            windowWidth: 0,
            windowHeight: 0,
            open_modal: false,
            property: { address: '' },
            state: 'United States',
            nearby_appeal_examples: [],
        };
    }

    componentDidMount() {
        let params = get_global_state()['params'];

        ajax_wrapper('GET', '/api/country_wide_examples/', {}, (data) =>
            this.setState({ loaded: true, nearby_appeal_examples: data }),
        );

        if (params['utm_source'] && !this.tracking) {
            this.tracking = true;

            let data = {
                utm_source: params['utm_source'],
                utm_medium: params['utm_medium'],
                utm_campaign: params['utm_campaign'],
                utm_content: params['utm_content'],
            };
            ajax_wrapper(
                'POST',
                '/api/facebook_ad_tracker/',
                data,
                this.save_ad_tracker,
            );
        }

        window.addEventListener('resize', () =>
            this.setState({
                windowWidth: window.innerWidth,
                windowHeight: window.innerHeight,
            }),
        );
    }

    save_ad_tracker = (data) => {
        console.log('Save Ad Tracker', data);
        localStorage.setItem('ad_tracker', data['id']);
    };

    select_property = (property) => {
        window.dataLayer.push({
            property_id: property.id,
            event: 'select_property',
        });

        let session_id = localStorage.getItem('session_id');
        if (session_id) {
            let unique_data = { property_id: property.id };
            let event_data = {
                session_id: session_id,
                event_name: 'select_property',
                event_data: unique_data,
            };
            ajax_wrapper('POST', '/api/create_event/', event_data, console.log);
        }

        let params = get_global_state()['params'];
        console.log('Params', params);
        if (params && params['email']) {
            this.setState({ property: property });
            let data = {
                email: params['email'],
                password: 'lowermytaxes',
                property_id: property.id,
            };
            data.email = data.email.toLowerCase();

            ajax_wrapper(
                'POST',
                '/user/register/',
                data,
                this.sign_up_callback,
            );
        } else {
            this.setState({ property: property });

            if (window.secret_react_state.user) {
                window.location.href = '/property_opportunities/' + property.id;
            }
        }
    };

    render() {
        let nearby_example_list = [];
        let comparables_for_map = [];
        for (let comparable of this.state.nearby_appeal_examples) {
            comparable.id = comparable.property_id;
            comparables_for_map.push(comparable);
        }

        let header_font_size = bound_font_size(4.2, 80, 40);

        let address_search_form = (
            <AddressSearch
                input_class="gradient-border-inverted thick"
                select_property={this.select_property}
            />
        );

        /*
        if (isMobile) {
            return <MobileInvestorsLandingPage />;
        }
        */

        return (
            <div style={{ overflow: 'hidden' }}>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Save Money On Your Property Taxes and Bills</title>
                    <meta
                        name="description"
                        content="An easy way to find where you are overpaying on property expenses."
                    />
                </Helmet>

                <Loading loaded={this.state.loaded}>
                    <div
                        className="hero-section"
                        style={{ marginBottom: '40px', position: 'relative' }}
                    >
                        <Container
                            className="slide"
                            style={{ paddingBottom: '0px' }}
                        >
                            <div
                                style={{
                                    textAlign: 'center',
                                }}
                            >
                                <div
                                    id="propertysearch"
                                    style={{
                                        fontSize: header_font_size + 'vw',
                                        fontWeight: '500',
                                        lineHeight: 'normal',
                                        marginBottom: '60px',
                                    }}
                                >
                                    <div>
                                        <b>AI Agents</b> That Save You On Your
                                        Property Taxes
                                    </div>
                                </div>
                            </div>

                            <div style={{ margin: '0px 10%' }}>
                                {address_search_form}
                            </div>

                            <div
                                className="row"
                                style={{
                                    margin: '10% 0px 0px',
                                    textAlign: 'center',
                                }}
                            >
                                <div className="col-md-4 col-12">
                                    <h1
                                        style={{
                                            fontSize: `${
                                                header_font_size / 1.5
                                            }vw`,
                                        }}
                                    >
                                        <b>120M+</b>
                                    </h1>
                                    <p>
                                        properties in our database, updated
                                        daily
                                    </p>
                                </div>

                                <div className="col-md-4 col-12">
                                    <h1
                                        style={{
                                            fontSize: `${
                                                header_font_size / 1.5
                                            }vw`,
                                        }}
                                    >
                                        <b>Over 90%</b>
                                    </h1>
                                    <p>of clients receive tax relief</p>
                                </div>

                                <div className="col-md-4 col-12">
                                    <h1
                                        style={{
                                            fontSize: `${
                                                header_font_size / 1.5
                                            }vw`,
                                        }}
                                    >
                                        <b>FREE</b>
                                    </h1>
                                    <p>to try and learn more</p>
                                </div>
                            </div>
                        </Container>
                    </div>

                    <div className="blue-background">
                        <Container className="slide ">
                            <div className="slide-header">
                                <h1
                                    style={{
                                        fontSize: `${header_font_size / 1.5}vw`,
                                        color: 'white',
                                    }}
                                >
                                    {"We've helped US Homeowners find over "}
                                    <b>$<CountUp duration={10} className="counter" end={100} />M</b>
                                    {' in tax savings'}
                                </h1>
                                <br />
                            </div>

                            <div className="row" style={{ margin: '0px' }}>
                                <div
                                    className="col-md-4 col-12"
                                    style={{ paddingBottom: '60px' }}
                                >
                                    <CustomerReview
                                        name={'Karen'}
                                        savings={'$400'}
                                        text={
                                            'Homebase helped me find and apply for $400/yr in exemptions per year. Thank you!!'
                                        }
                                    />
                                </div>
                                <div
                                    className="col-md-4 col-12"
                                    style={{ paddingBottom: '60px' }}
                                >
                                    <CustomerReview
                                        name={'Ahad'}
                                        savings={'$1000s'}
                                        text={
                                            'My family home was overpaying by $1000s of dollars. HomeBase helped me see that and figure out how to appeal.'
                                        }
                                    />
                                </div>
                                <div
                                    className="col-md-4 col-12"
                                    style={{ paddingBottom: '60px' }}
                                >
                                    <CustomerReview
                                        name={'Chuck'}
                                        savings={'$370'}
                                        text={
                                            'My family home was overpaying by $1000s of dollars. HomeBase helped me see that and figure out how to appeal.'
                                        }
                                    />
                                </div>
                            </div>
                        </Container>
                    </div>

                    <div className="">
                        <div className="slide">
                            <div className="slide-header">
                                <h1
                                    style={{
                                        fontSize: `${header_font_size / 1.5}vw`,
                                    }}
                                >
                                    All Across America, Homeowners Are Getting Sc#ewed Over!
                                </h1>
                                <p>See Below To See Example Homes and How Much They Are Overpaying!</p>
                            </div>

                            <Loading
                                loaded={
                                    this.state.nearby_appeal_examples.length > 0
                                }
                                cover={true}
                            >
                                <ComparableMap
                                    comparables={comparables_for_map}
                                    no_info={true}
                                    show_number={true}
                                />
                            </Loading>
                            <Container>
                                <div>
                                    <ExampleHomes homes={comparables_for_map} />
                                </div>
                                <div className="row">
                                    <div
                                        className="col-md-4 col-12"
                                        style={{ margin: '20px 0px' }}
                                    >
                                        <NewsArticle
                                            logo={'/static/images/jersey.png'}
                                            link={
                                                'https://jcitytimes.com/mayor-calls-refund-of-property-tax-overcharge-a-tax-reduction/?utm_source=chatgpt.com'
                                            }
                                            text={
                                                'Mayor Calls Refund of Property Tax Overcharge a “Tax Reduction”'
                                            }
                                        />
                                    </div>
                                    <div
                                        className="col-md-4 col-12"
                                        style={{ margin: '20px 0px' }}
                                    >
                                        <NewsArticle
                                            logo={'/static/images/detroit.png'}
                                            link={
                                                'https://www.detroitnews.com/story/news/local/detroit-city/housing/2020/01/09/detroit-homeowners-overtaxed-600-million/2698518001/?utm_source=chatgpt.com'
                                            }
                                            text={
                                                'Detroit homeowners overtaxed $600 million'
                                            }
                                        />
                                    </div>
                                    <div
                                        className="col-md-4 col-12"
                                        style={{ margin: '20px 0px' }}
                                    >
                                        <NewsArticle
                                            logo={'/static/images/wmar.png'}
                                            link={
                                                'https://www.wmar2news.com/matterformallory/audit-shows-maryland-homeowners-were-overtaxed-millions-of-dollars?utm_source=chatgpt.com'
                                            }
                                            text={
                                                "Audit shows Maryland homeowners were overtaxed millions of dollars State doesn't plan to return the money"
                                            }
                                        />
                                    </div>
                                </div>
                            </Container>
                        </div>
                    </div>

                    <div
                        className="gradient-background-darker"
                        style={{
                            borderRadius: '32px',
                            margin: '0px 6px',
                            textAlign: 'center',
                            padding: '40px 20px 60px ',
                        }}
                    >
                        <Container>
                            <h1
                                style={{
                                    fontSize: `${header_font_size / 1.5}vw`,
                                }}
                            >
                                {'Why Wait? Find Out About Your Taxes Today!'}
                            </h1>
                            <h5>Enter your address below</h5>
                            <br />
                            <div style={{ margin: '0px 10%' }}>
                                <AddressSearch
                                    select_property={this.select_property}
                                />
                            </div>
                        </Container>
                    </div>

                    

                    <FAQs />

                    <SignUpModal
                        property={this.state.property}
                        close={() => this.setState({ property: {} })}
                    />
                </Loading>
            </div>
        );
    }
}
