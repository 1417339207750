import React, { Component } from 'react';
import { DOLLAR_STYLING } from 'constants';
import { format_date } from 'functions';
import PrettyAddress from './pretty_address';

import { Container, Button } from 'library';
import UnlockButton from 'components/unlock_button';
import ComparableMap from 'components/comparable_map';

class ComparableRow extends Component {
    render() {
        let property = this.props.property;
        let comparable = this.props.comparable;

        let latlng = comparable.latitude + ',' + comparable.longitude;

        let img_url =
            'https://maps.googleapis.com/maps/api/streetview?location=' +
            latlng +
            '&size=600x400&key=AIzaSyCTeyTeeIoM-qDCe1Z8W7OK0qDVAJ8QzhU';

        let savings = comparable.savings;

        let savings_type = <span>save</span>;
        if (savings < 0) {
            savings_type = <strong style={{ color: 'red' }}>lose</strong>;
            savings = Math.abs(savings);
        }

        let highlight = {};
        if (this.props.highlight) {
            highlight = {
                background: 'linear-gradient(to right, #fa724d, #5e51f7)',
                padding: '2px',
            };
        }

        return (
            <div style={highlight}>
                <div className="simple-card" style={{ overflow: 'hidden' }}>
                    <a
                        href={'/property_opportunities/' + comparable.id}
                        target="_blank"
                        style={{ textDecoration: 'none' }}
                    >
                        <div
                            className="background-image"
                            style={{
                                backgroundImage: `url(${img_url})`,
                                backgroundPosition: 'center',
                                margin: '-10px -20px 0px',
                                width: 'calc(100% + 40px)',
                            }}
                        ></div>

                        <PrettyAddress
                            property={comparable.address}
                            small={true}
                        />
                    </a>
                    <div className="line-item">
                        <label>Distance from you:</label>
                        <span>{comparable.distance} miles</span>
                    </div>
                    <div className="page-break"></div>

                    <div className="line-item">
                        <label>Sold On:</label>
                        <span>{format_date(comparable.lastSaleDate)}</span>
                    </div>
                    <div className="line-item">
                        <label>Sold For:</label>
                        <span>
                            {parseFloat(
                                comparable.lastSaleAmount,
                            ).toLocaleString('en-US', DOLLAR_STYLING)}
                        </span>
                    </div>
                    <div className="page-break"></div>

                    <div className="line-item">
                        <label>Living:</label>
                        <span>{comparable.squareFeet} Sq. Ft.</span>
                    </div>
                    <div className="line-item">
                        <label>Acreage:</label>
                        <span>{comparable.lotSquareFeet} Sq. Ft.</span>
                    </div>

                    <div className="page-break"></div>

                    <div style={{ textAlign: 'center' }}>
                        <h5>
                            If your property were taxed the same way, you’d{' '}
                            {savings_type}{' '}
                            <span className="gradient-color">
                                {savings.toLocaleString(
                                    'en-US',
                                    DOLLAR_STYLING,
                                )}
                            </span>{' '}
                            per year.
                        </h5>
                        <UnlockButton />
                    </div>
                </div>
            </div>
        );
    }
}

export default class Comparables extends Component {
    constructor(props) {
        super(props);

        this.state = { selected_comp: null };

        this.select_comp = this.select_comp.bind(this);
    }

    select_comp(comp) {
        this.setState({ selected_comp: comp });
    }

    render() {
        let property = this.props.property;

        console.log('Comparables Property', property);

        let comparable_list = [];
        let comparables_for_map = [];

        let error = null;
        if (this.props.comparables.length === 0) {
            error = (
                <h4>
                    No comparables found. We'll reach out if there is an
                    opportunity to appeal in the future. Check below for other
                    potential savings.
                </h4>
            );
        }

        let appeal = null;
        if (
            this.props.comparables.length > 0 &&
            this.props.average_savings > 0 &&
            window.secret_react_state.user
        ) {
            appeal = (
                <Button
                    className="gradient-border"
                    {...this.props}
                    style={{ fontSize: '30px' }}
                    onClick={() =>
                        this.props.want_to_file({
                            name: 'Property Tax Appeal',
                            id: null,
                            max_value: this.props.average_savings,
                        })
                    }
                >
                    <div
                        className="gradient-border-content"
                        style={{ fontWeight: 500 }}
                    >
                        {'File An Appeal'}
                    </div>
                </Button>
            );
        }

        for (let comparable of this.props.comparables) {
            console.log('Comparables', this.props.comparables, comparable);
            let highlight = false;
            if (
                this.state.selected_comp &&
                comparable.id == this.state.selected_comp.id
            ) {
                highlight = true;
            }

            comparable_list.push(
                <div className="col-lg-4 col-12">
                    <ComparableRow
                        small={true}
                        comparable={comparable}
                        property={this.props.property}
                        highlight={highlight}
                    />
                </div>,
            );

            comparables_for_map.push(comparable);

            if (comparable_list.length >= 3) {
                break;
            }
        }

        let unlock = null;
        if (!window.secret_react_state['user']) {
            unlock = (
                <div>
                    <h6
                        style={{ display: 'inline-block', paddingRight: '4px' }}
                    >
                        Unlock to view more similar taxed homes
                    </h6>
                    <UnlockButton />
                </div>
            );
        }

        return (
            <div>
                <div className="slide-header">
                    <h4>Similar Homes Nearby</h4>
                    <h6>
                        These are similar houses in your area to see if you have
                        been taxed unfairly.
                    </h6>

                    {error}
                </div>

                <ComparableMap
                    property={property}
                    comparables={comparables_for_map}
                    final_section={
                        <div>
                            Explore more mortgage offers and save extra!
                            <div style={{ height: '10px' }}></div>
                            <UnlockButton />
                        </div>
                    }
                    no_info={true}
                />

                <Container>
                    <div className="row" style={{ marginTop: '15px' }}>
                        {comparable_list}
                    </div>
                    <div
                        style={{
                            textAlign: 'center',
                            padding: '40px 0px 20px 0px',
                        }}
                    >
                        {unlock}
                        {appeal}
                    </div>
                </Container>
            </div>
        );
    }
}
