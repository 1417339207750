import React, {useState} from 'react';
import {useStripe, useElements, PaymentElement} from '@stripe/react-stripe-js';
import { ajax_wrapper, get_global_state } from 'functions';

const CheckoutForm = (props) => {
  const stripe = useStripe();
  const elements = useElements();

  const [errorMessage, setErrorMessage] = useState(null);

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (window.secret_react_state.filing_options) {
      window.dataLayer.push({
        'exemption': window.secret_react_state.filing_options.name,
        'conversion_value': 20,
        'event': 'purchase'
      });
  
      let session_id = localStorage.getItem('session_id');
      if (session_id) {
          let unique_data = {'exemption': window.secret_react_state.filing_options.name, 'conversion_value': 50}
          let event_data = {'session_id': session_id, 'event_name': 'purchase', 'event_data': unique_data}
          ajax_wrapper('POST', '/api/create_event/', event_data, console.log);
      }
    }

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const {error} = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: 'https://yourhomebase.co/complete_payment/',
      },
    });


    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      setErrorMessage(error.message);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
      
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <button disabled={!stripe} className="gradient-border"><div
                            className="gradient-border-content"
                            style={{ fontWeight: 500 }}
                        >
                            {'Get My Filing Started'}
                        </div></button>
      {/* Show error message to your customers */}
      {errorMessage && <div>{errorMessage}</div>}
    </form>
  )
};

export default CheckoutForm;



