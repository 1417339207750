import React, { Component } from 'react';
import { Container, Button } from 'library';
import { isMobile } from 'react-device-detect';
import { ajax_wrapper, bound_font_size } from 'functions';

import Navigation from './navigation';

export default class Underpaying extends Component {
    constructor(props) {
        super(props);
        this.click_cta = this.click_cta.bind(this);
    }

    click_cta() {
        let session_id = localStorage.getItem('session_id');
        if (session_id) {
            let event_data = {
                session_id: session_id,
                event_name: 'underpaying_cta',
                event_data: {},
            };
            ajax_wrapper('POST', '/api/create_event/', event_data, console.log);
        }
        let href = window.location.href;
        href = href.substring(0, href.indexOf(','));

        window.location.href = href + '#exemptions';
    }

    render() {
        let navigation = <Navigation />;

        let header_font_size = bound_font_size(4.2, 80, 40);

        return (
            <div
                className="hero-section gradient-background-underpaying"
                style={{
                    marginBottom: '40px',
                    position: 'relative',
                }}
            >
                <div className="confetti d-none d-lg-block"></div>
                <div className="confetti d-none d-lg-block right"></div>
                <Container
                    min_height={true}
                    className="slide nav-wrapper"
                    style={{
                        textAlign: 'center',
                        maxWidth: '100%',
                        position: 'relative',
                        zIndex: '20',
                    }}
                >
                    <div>
                        <div
                            id="propertysearch"
                            style={{
                                fontSize: header_font_size + 'vw',
                                fontWeight: '500',
                                lineHeight: 'normal',
                            }}
                        >
                            <div>Congrats! You are...</div>
                            <div
                                className="gradient-color"
                                style={{ fontSize: '1.4em' }}
                            >
                                Underpaying ↓
                            </div>
                        </div>
                    </div>
                    <div>
                        <h4
                            style={{
                                fontSize: header_font_size / 2 + 'vw',
                                display: 'inline-block',
                                margin: '15% 0px 10px',
                            }}
                        >
                            However, you can save{' '}
                            <span class="gradient-color">hundreds</span> more.{' '}
                            <span class="gradient-color">Click the Button</span>{' '}
                            to see how!
                        </h4>
                        <br />
                        <Button
                            className="gradient-border"
                            onClick={this.click_cta}
                        >
                            <div
                                className="gradient-border-content"
                                style={{
                                    fontWeight: 500,
                                    fontSize: header_font_size / 3 + 'vw',
                                }}
                            >
                                {'Take Me There'}
                            </div>
                        </Button>
                    </div>

                    {navigation}
                </Container>
            </div>
        );
    }
}
