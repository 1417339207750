import { ajax_wrapper, get_global_state } from 'functions';
import { Container } from 'library';
import React, { Component } from 'react';

export default class PropertyInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            property: {},
        }

        this.load_property = this.load_property.bind(this);
        this.renderNestedDict = this.renderNestedDict.bind(this);
        this.renderListItems = this.renderListItems.bind(this);
    }

    componentDidMount() {
        let property_id = get_global_state()['params'].property_id;
        ajax_wrapper('GET','/api/property_info/?property_id=' + property_id, {}, this.load_property);
    }

    load_property(data) {
        this.setState({ property: data['property'] });
    }

    renderNestedDict(dict, level = 0) {
        let left_margin_style = {marginLeft: `${level * 10}px`};
        return Object.entries(dict).map(([key, value], index) => {
          // Check if value is an object (and not null or an array)
          const isNestedDict = value !== null && 
                               typeof value === 'object' && 
                               !Array.isArray(value);
          
          // Check if value is an array
          const isList = Array.isArray(value);
          
          if (isNestedDict) {
            // Render as a header with nested content
            return (
              <div key={index} className="mt-2" style={left_margin_style}>
                <h3 className="font-bold text-lg">{key}</h3>
                <div className="ml-6 pl-2 border-l-2 border-gray-300">
                  {this.renderNestedDict(value, level + 1)}
                </div>
              </div>
            );
          } else if (isList) {
            // Render list as a header with indexed items
            return (
              <div key={index} className="mt-2" style={left_margin_style}>
                <h3 className="font-bold text-lg">{key}</h3>
                <div className="ml-6 pl-2 border-l-2 border-gray-300">
                  {value.map((item, itemIndex) => {
                    if (item !== null && typeof item === 'object') {
                      // If list item is an object or another array
                      return (
                        <div key={itemIndex} className="mt-2">
                          <h4 className="font-medium">{key}[{itemIndex}]</h4>
                          <div className="ml-4 pl-2 border-l-2 border-gray-300">
                            {typeof item === 'object' && !Array.isArray(item) 
                              ? this.renderNestedDict(item, level + 2)
                              : Array.isArray(item)
                                ? this.renderListItems(item, `${key}[${itemIndex}]`, level + 2)
                                : String(item)}
                          </div>
                        </div>
                      );
                    } else {
                      // Simple list item
                      return (
                        <div key={itemIndex} className="py-1" style={left_margin_style}>
                          <span className="font-medium">{key}[{itemIndex}]:</span> {String(item)}
                        </div>
                      );
                    }
                  })}
                </div>
              </div>
            );
          } else {
            // Render as a simple key-value pair
            return (
              <div key={index} className="py-1" style={left_margin_style}>
                <span className="font-medium">{key}:</span> {
                  value === null ? 'null' : String(value)
                }
              </div>
            );
          }
        });
      }
      
      renderListItems(list, keyPrefix, level) {
        let left_margin_style = {marginLeft: `${level * 10}px`};
        return list.map((item, index) => {
          if (item !== null && typeof item === 'object') {
            return (
              <div key={index} className="mt-1" style={left_margin_style}>
                <h4 className="font-medium">{keyPrefix}[{index}]</h4>
                <div className="ml-4 pl-2 border-l-2 border-gray-300">
                  {typeof item === 'object' && !Array.isArray(item)
                    ? this.renderNestedDict(item, level + 1)
                    : Array.isArray(item)
                      ? this.renderListItems(item, `${keyPrefix}[${index}]`, level + 1)
                      : String(item)}
                </div>
              </div>
            );
          } else {
            return (
              <div key={index} className="py-1" style={left_margin_style}>
                <span className="font-medium">{keyPrefix}[{index}]:</span> {String(item)}
              </div>
            );
          }
        });
      }
    
      render() {
        let data = this.state.property;
        console.log("If Statement", !data, typeof data, Object.keys(data).length);
        
        if (!data || typeof data !== 'object' || Object.keys(data).length === 0) {
          return <div>No data to display</div>;
        }
    
        return (
            <Container>
                <div className="p-4 bg-white rounded shadow">
                    <h1>{this.state.property.propertyInfo.address.label}</h1>
                    {this.renderNestedDict(data)}
                </div>
            </Container>
        );
      }
    }
    

