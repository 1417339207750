import React, { Component } from 'react';

import { ajax_wrapper, get_global_state, format_date } from 'functions';
import { DOLLAR_STYLING } from 'constants';
import { PrettyAddress, UnlockButton } from 'components';
import { Button, Modal } from 'library';
import { ContactUs } from 'pages';

export default class PropertyDetails extends Component {
    constructor(props) {
        super(props);

        this.state = { contact_us: false };

        this.toggle_contact_us = this.toggle_contact_us.bind(this);
        this.to_dollar_string = this.to_dollar_string.bind(this);
        this.to_number_string = this.to_number_string.bind(this);
    }

    toggle_contact_us() {
        this.setState({ contact_us: !this.state.contact_us });
    }

    get_property() {
        let params = get_global_state()['params'];
        localStorage.setItem('property_id', params['property_id']);
        ajax_wrapper(
            'GET',
            '/api/property_opportunities/',
            { property_id: params['property_id'] },
            this.load_property,
        );
    }

    load_property(data) {
        data['loaded'] = true;
        this.setState(data);

        if (!this.initializedAds) {
            setTimeout(() => {
                window.initializeMyFinance(true);
                this.initializedAds = true;
            }, 500);
        }
    }

    submit_info(data) {
        ajax_wrapper(
            'POST',
            '/user/submit_demographics/',
            { data: data },
            console.log,
        );
        this.setState({ qualify_for_exemptions: data });
    }

    want_to_file(exemption, diy) {
        let params = get_global_state()['params'];

        ajax_wrapper(
            'POST',
            '/api/want_to_file/',
            {
                exemption_type: exemption,
                diy: diy,
                property_id: params['property_id'],
            },
            () => this.setState({ filing: true }),
        );
    }

    to_dollar_string(value, value_on_error) {
        try {
            if (value == null) {
                return value_on_error;
            } else {
                return parseFloat(value).toLocaleString(
                    'en-US',
                    DOLLAR_STYLING,
                );
            }
        } catch {
            return value_on_error;
        }
    }

    to_number_string(value, value_on_error) {
        if (value == null) {
            return value_on_error;
        } else {
            try {
                return parseFloat(value).toLocaleString('en-US');
            } catch {
                return value_on_error;
            }
        }
    }

    render() {
        let property = this.props.property;

        let image_size = 'col-12 col-lg-7';
        let text_size = 'col-12 col-lg-5';
        if (this.props.small) {
            image_size = 'col-12';
            text_size = 'col-12';
        }

        let latlng =
            property.propertyInfo.latitude +
            ',' +
            property.propertyInfo.longitude;

        let img_url =
            'https://maps.googleapis.com/maps/api/streetview?location=' +
            latlng +
            '&size=600x400&key=AIzaSyCTeyTeeIoM-qDCe1Z8W7OK0qDVAJ8QzhU';

        let current_mortgages = null;

        if (property.currentMortgages && property.currentMortgages.length > 0) {
            let mortgage_list = [];
            for (let mortgage of property.currentMortgages) {
                mortgage_list.push(
                    <tr>
                        <td>{mortgage.loanType}</td>
                        <td>{this.to_dollar_string(mortgage, 'NA')}</td>
                        <td>{format_date(mortgage.documentDate)}</td>
                    </tr>,
                );
            }

            current_mortgages = (
                <div style={{ marginBottom: '20px' }}>
                    <div className="line-item">
                        Currently the following mortgages are listed on the
                        property.
                    </div>
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Loan Type</th>
                                <th>Amount</th>
                                <th>Date Loan Started</th>
                            </tr>
                        </thead>
                        <tbody>{mortgage_list}</tbody>
                    </table>
                    <div className="page-break"></div>
                </div>
            );
        } else {
            current_mortgages = (
                <div style={{ padding: '8px 6px' }}>
                    Looks like there are no mortgages on the house currently.
                    Congratulations. If however, you needed some cash, you could
                    mortgage your house and get cash now. Check out rates below.
                </div>
            );
        }

        let realtor = null;
        if (
            property.mlsHistory.length > 0 &&
            property.mlsHistory[0].agentName
        ) {
            realtor = [
                <div className="line-item inline">
                    <label>Realtor:</label>
                    <span>{property.mlsHistory[0].agentName}</span>
                </div>,
                <div className="line-item inline">
                    <label>Realtor Email:</label>
                    <span>{property.mlsHistory[0].agentEmail}</span>
                </div>,
            ];
        }

        let owner_details = null;
        if (
            window.secret_react_state.user &&
            window.secret_react_state.user.id
        ) {
            owner_details = (
                <div className="line-item">
                    <label>Owner Details</label>
                    <span>
                        Owner 1: {property.ownerInfo.owner1FullName}, Owner 2:{' '}
                        {property.ownerInfo.owner2FullName}
                    </span>
                </div>
            );
        }

        let financial_info = null;

        let sold_on = 'N/A';
        if (property.lastSaleDate) {
            sold_on = new Date(property.lastSaleDate).toLocaleString('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
            });
        }

        if (!this.props.small) {
            financial_info = [
                <div className="col-12">
                    <div className="grey-header">Last Sale</div>
                    <div>
                        <div className="line-item inline">
                            <label>Sold On:</label>
                            <span>{sold_on}</span>
                        </div>
                        <div className="line-item inline">
                            <label>Sold For:</label>
                            <span>
                                {this.to_dollar_string(
                                    property.lastSalePrice,
                                    'NA',
                                )}
                            </span>
                        </div>
                        {realtor}
                    </div>
                </div>,
                <div className="col-12">
                    <div className="grey-header">Mortgage</div>
                    {current_mortgages}

                    <div style={{ textAlign: 'center', padding: '10px' }}>
                        <div
                            className="gradient-border"
                            style={{
                                display: 'inline-block',
                                borderSize: '6px',
                                padding: '4px',
                            }}
                        >
                            <div className="gradient-border-content">
                                <div
                                    style={{
                                        display: 'inline-block',
                                        fontSize: '1.4rem',
                                        fontWeight: '500',
                                    }}
                                >
                                    Explore more mortgage offers and save extra!
                                </div>
                                <Button
                                    style={{
                                        borderRadius: '100px',
                                        marginLeft: '10px',
                                        verticalAlign: 'inherit',
                                    }}
                                    type="dark"
                                    href="#mortgages"
                                >
                                    See Offers
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>,
            ];
        }

        return (
            <div
                className="simple-card"
                style={{ padding: '20px', marginBottom: '80px' }}
            >
                <div className="row">
                    <div
                        className={image_size}
                        style={{ marginBottom: '10px' }}
                    >
                        <div
                            className="background-image"
                            style={{
                                backgroundImage: `url(${img_url})`,
                                borderRadius: '6px',
                                backgroundPosition: 'center',
                                height: '100%',
                            }}
                        ></div>
                    </div>
                    <div className={text_size} style={{ marginBottom: '10px' }}>
                        <div>
                            <PrettyAddress
                                property={property.propertyInfo.address}
                            />

                            <div className="grey-header">Property Details</div>
                            <div className="line-item">
                                <label>Estimated Value:</label>
                                <span>
                                    {this.to_dollar_string(
                                        property.estimatedValue,
                                        'NA',
                                    )}
                                </span>
                            </div>
                            <div className="line-item">
                                <label>Total Assessed Value:</label>
                                <span>
                                    {this.to_dollar_string(
                                        property.taxInfo.assessedValue,
                                        'NA',
                                    )}
                                </span>
                            </div>
                            <div className="line-item">
                                <img
                                    src="/static/images/logo_small.svg"
                                    style={{
                                        height: '20px',
                                        marginRight: '4px',
                                    }}
                                ></img>
                                <label>Yearly Taxes:</label>
                                <span>
                                    {this.to_dollar_string(
                                        property.taxInfo.taxAmount,
                                        'NA',
                                    )}
                                </span>
                            </div>
                            <div className="line-item">
                                <label>Possible tax savings:</label>
                                <span>
                                    {this.props.savings}
                                    <UnlockButton />
                                </span>
                            </div>
                            <div className="page-break"></div>

                            <div className="line-item">
                                <label>Living:</label>
                                <span>
                                    {this.to_number_string(
                                        property.propertyInfo.livingSquareFeet,
                                        'NA',
                                    )}{' '}
                                    Sq. Ft.
                                </span>
                            </div>
                            <div className="line-item">
                                <label>Acreage:</label>
                                <span>
                                    {this.to_number_string(
                                        property.lotInfo.lotSquareFeet,
                                        'NA',
                                    )}{' '}
                                    Sq. Ft.
                                </span>
                            </div>

                            <div
                                style={{
                                    display: 'inline-block',
                                    padding: '0px 6px',
                                }}
                            >
                                <div
                                    style={{
                                        fontSize: '1.6rem',
                                        fontWeight: '500',
                                    }}
                                >
                                    {property.propertyInfo.bedrooms || 0}
                                </div>
                                <div>Beds</div>
                            </div>
                            <div
                                style={{
                                    display: 'inline-block',
                                    padding: '0px 6px',
                                }}
                            >
                                <div
                                    style={{
                                        fontSize: '1.6rem',
                                        fontWeight: '500',
                                    }}
                                >
                                    {property.propertyInfo.bathrooms || 0}
                                </div>
                                <div>Baths</div>
                            </div>

                            <div className="page-break"></div>

                            <div className="line-item">
                                <label>Block:</label>
                                <span>{property.lotInfo.censusBlock}</span>
                            </div>
                            <div className="line-item">
                                <label>Lot:</label>
                                <span>{property.lotInfo.lotNumber}</span>
                            </div>
                            <div className="line-item">
                                <label>Assessor Number:</label>
                                <span>{property.lotInfo.apn}</span>
                            </div>

                            {owner_details}

                            {this.props.report_issue ? (
                                <div>
                                    <div className="page-break"></div>

                                    <div style={{ textAlign: 'center' }}>
                                        <h3>Something wrong with the data?</h3>
                                        <br />
                                        <Button
                                            type="dark"
                                            style={{ borderRadius: '100px' }}
                                            onClick={this.toggle_contact_us}
                                        >
                                            Report Issue
                                        </Button>
                                        <Modal
                                            style={{ maxWidth: '600px' }}
                                            show={this.state.contact_us}
                                            onHide={this.toggle_contact_us}
                                        >
                                            <ContactUs
                                                subject={
                                                    'Issue With Property: ' +
                                                    this.props.property
                                                        .propertyInfo.address
                                                        .label
                                                }
                                            />
                                        </Modal>
                                    </div>
                                </div>
                            ) : null}
                        </div>
                    </div>

                    {financial_info}
                </div>
            </div>
        );
    }
}
