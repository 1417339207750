import React, { Component } from 'react';
import { Button, Modal, Form, TextInput } from 'library';
import { ajax_wrapper, get_global_state } from 'functions';
const GOOGLE_CLIENT_ID =
    '378099360565-n2jb6ia8u7gr4s85u1d8l0ckp01mfq0e.apps.googleusercontent.com';

export default class SignUpModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            client: null,
        }

        this.google_login = this.google_login.bind(this);
        this.google_error = this.google_error.bind(this);
        this.login_callback = this.login_callback.bind(this);
        this.google_sign_up_callback = this.google_sign_up_callback.bind(this);
    }

    componentDidMount() {
        try {
            let client = window.google.accounts.oauth2.initTokenClient({
                client_id: GOOGLE_CLIENT_ID,
                scope: 'email',
                callback: (response) => this.google_login(response),
                error_callback: (response) => this.google_error(response),
            });

            this.setState({ client: client });
        }
        catch {
            console.log("Failed to Initialize Oauth")
        }
    }

    google_login(state) {
        if (state && state.access_token) {
            ajax_wrapper(
                'POST',
                '/user/google_login/',
                state,
                this.google_sign_up_callback,
            );
        }
    }

    google_error(state) {
        console.log('Google Error', state);
        this.setState({ error: state['message'] });
    }

    submit = (value) => {
        let name = value['name'];
        let email = value['email'];
        let property_id = this.props.property_id;

        let ad_tracker_id = localStorage.getItem('ad_tracker');
        if (ad_tracker_id) {
            ajax_wrapper('POST', '/api/facebook_ad_tracker/', {id: ad_tracker_id, email: email}, console.log);
        }

        let session_id = localStorage.getItem('session_id');
        if (session_id) {
            let event_data = {'session_id': session_id, 'email': email}
            ajax_wrapper('POST', '/api/create_session/', event_data, console.log);
        }

        if (session_id) {
            let unique_data = {'property_id': property_id}
            let event_data = {'session_id': session_id, 'event_name': 'unlock_sign_up', 'event_data': unique_data}
            ajax_wrapper('POST', '/api/create_event/', event_data, console.log);
        }

        let data = {
            name: name,
            email: email,
            password: 'lowermytaxes',
            property_id: property_id,
        };
        data.email = data.email.toLowerCase();
        //console.log("data", data)

        ajax_wrapper('POST', '/user/register/', data, this.login_callback);

        window.dataLayer.push({
        'event': 'unlock_sign_up',
        'property_id': property_id,
        });

        
    };

    google_sign_up_callback(value) {
        localStorage.setItem('token', value.access);
        localStorage.setItem('refresh_token', value.refresh);
        localStorage.setItem('token_time', new Date());

        let property_id = this.props.property.id;
        let email = value['email']

        let ad_tracker_id = localStorage.getItem('ad_tracker');
        if (ad_tracker_id) {
            ajax_wrapper('POST', '/api/facebook_ad_tracker/', {id: ad_tracker_id, email: email}, console.log);
        }

        let session_id = localStorage.getItem('session_id');
        if (session_id) {
            let event_data = {'session_id': session_id, 'email': email}
            ajax_wrapper('POST', '/api/create_session/', event_data, console.log);
        }

        if (session_id) {
            let unique_data = {'property_id': property_id}
            let event_data = {'session_id': session_id, 'event_name': 'google_sign_up', 'event_data': unique_data}
            ajax_wrapper('POST', '/api/create_event/', event_data, console.log);
        }

        window.dataLayer.push({
        'event': 'unlock_sign_up',
        'property_id': property_id,
        });

        window.location.href = '/property_opportunities/' + property_id;
    }

    login_callback = (value) => {
        if ('error' in value) {
            if (value.error === 'Invalid Credentials') {
                this.setState({
                    error: (
                        <p>
                            Wrong Email or Password. If this is your first time
                            logging in, you may need to{' '}
                            <a href="/password_reset_request/">
                                reset your password first.
                            </a>
                        </p>
                    ),
                });
            } else {
                this.setState({ error: value.error });
            }
        } else {
            localStorage.setItem('token', value.access);
            localStorage.setItem('refresh_token', value.refresh);
            localStorage.setItem('token_time', new Date());

            let property_id = this.props.property.id;

            window.location.href = '/property_opportunities/' + property_id;
        }
    };

    render() {
        let style = Object.assign(
            { display: 'inline-block' },
            this.props.style,
        );

        let show = false;
        if (this.props.property.id) {
            show = true;
        }

        let google_button = null;
        if (this.state.client) {
            google_button = (
                <Button
                    onClick={() => this.state.client.requestAccessToken()}
                    style={{ border: '1px solid black', width: '100%' }}
                >
                    <div
                        style={{
                            marginRight: '10px',
                            display: 'inline-block',
                            lineHeight: '20px',
                        }}
                    >
                        {'Login with Google'}
                    </div>
                    <img
                        style={{
                            width: 'auto',
                            display: 'inline-block',
                            verticalAlign: 'top',
                        }}
                        src="https://lh3.googleusercontent.com/COxitqgJr1sJnIDe8-jiKhxDx1FrYbtRHKJ9z_hELisAlapwE9LUPh6fcXIfb5vwpbMl4xl9H9TRFPc5NOO8Sb3VSgIBrfRYvW6cUA"
                        alt=""
                    />
                </Button>
            );
        }

        return (
            <div style={style}>
                <Modal
                    show={show}
                    style={{ width: '600px' }}
                    onHide={this.props.close}
                >
                    <div style={{ padding: '20px' }}>
                        <div style={{ textAlign: 'center' }}>
                            <h1
                                style={{
                                    fontSize: '48px',
                                    fontWeight: 'bold',
                                }}
                            >
                                You could save{' '}
                                <span className="gradient-color">
                                    $1000s
                                </span>{' '}
                                on your taxes!
                            </h1>
                            <h5>
                                Give us a little more of information about
                                yourself so we can send over your properties potential savings!
                            </h5>
                        </div>
                        <br />
                        
                        
                        {google_button}
                        

                        <div style={{width:'100%', borderBottom: '1px solid lightgrey', margin:'20px 0px'}}></div>

                        <Form
                            submit_className="gradient big-submit"
                            submit_text="Submit"
                            submit={this.submit}
                        >
                            <TextInput
                                placeholder="@ Type your email"
                                name="email"
                                className="fancy-input"
                            />
                        </Form>
                    </div>
                </Modal>
            </div>
        );
    }
}
