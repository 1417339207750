import React, { Component } from 'react';
import { ajax_wrapper, get_global_state } from 'functions';
import { Loading, Container, Button, Form, TextInput, Alert } from 'library';

class Requirement extends Component {
    constructor(props) {
        super(props);

        this.delete = this.delete.bind(this);
    }

    delete() {
        ajax_wrapper("POST", '/api/home/propertytaxexemptionrequirement/' + this.props.requirement.id + '/', {}, this.props.get_requirements)
    }

    render() {
        return (
            <div style={{padding:'20px', borderTop:'1px solid gray', borderBottom:'1px solid gray'}}>
                <p>{this.props.requirement.description}</p>
                <Button type='danger' onClick={this.delete}><i class="bi bi-trash" /></Button>
            </div>
        )
    }
}

export default class CreateExemption extends Component {
    constructor(props) {
        super(props);

        this.state = {
            property_tax_exemption: {},
            requirements: [],
            loaded: false,
            exemption_saved: false,
        };

        this.get_exemption = this.get_exemption.bind(this);
        this.load_exemption = this.load_exemption.bind(this);
        this.get_requirements = this.get_requirements.bind(this);
        this.load_requirements = this.load_requirements.bind(this);

        this.save_exemption = this.save_exemption.bind(this);
        this.save_exemption_requirement = this.save_exemption_requirement.bind(this);
    }

    componentDidMount() {
        let params = get_global_state()['params']
        if (params['id']) {
            this.get_exemption();
        }
        else {
            this.setState({loaded:true})
        }
    }

    get_exemption() {
        let params = get_global_state()['params']
        ajax_wrapper('GET','/api/home/propertytaxexemption/' + params['id'] + '/', {}, this.load_exemption)
        
    }

    save_exemption(data, callback) {
        ajax_wrapper('POST','/api/save_property_tax_exemption/', data, this.load_exemption)
        callback();
    }

    load_exemption(data) {
        let params = get_global_state()['params']
        if (!params['id']) {
            window.location.href = '/admin/create_exemption/' + data['id'] + '/';
        }
        this.setState({property_tax_exemption: data, loaded: true}, this.get_requirements)
    }

    get_requirements() {
        let params = get_global_state()['params']
        ajax_wrapper('GET','/api/home/propertytaxexemptionrequirement/list/', {'property_tax_exemption_id': params['id']}, this.load_requirements)
    }

    load_requirements(data) {
        this.setState({requirements: data, loaded:true})
    }

    save_exemption_requirement(data, callback) {
        data['property_tax_exemption'] = this.state.property_tax_exemption.id
        ajax_wrapper('POST','/api/save_property_tax_exemption_requirement/', data, this.get_requirements)
        callback();
    }


    render() {

        let requirements = <div></div>
        if (this.state.property_tax_exemption.id) {
            let requirement_list = []
            for (let req of this.state.requirements) {
                requirement_list.push(<Requirement requirement={req} get_requirements={this.get_requirements} />)
            }

            requirements =  <div className="simple-card">
                <h3>Requirements</h3>

                {requirement_list}

                <div style={{height:'50px'}}></div>
                <p><b>Add New Requirement</b></p>
                <Form submit={this.save_exemption_requirement}>
                    <TextInput
                        label='Requirement Description'
                        name="description"
                    />
                </Form>
            </div>
        }

        return (
            <Container>

                <Loading loaded={this.state.loaded}>
                    <div className="simple-card">
                        <h3>Property Tax Exemption</h3>
                        <Form   
                            submit={this.save_exemption}
                            global_state_name="exemption_form"
                            defaults = {this.state.property_tax_exemption}
                        >

                            <TextInput
                            label='Exemption Name'
                                name="name"
                            />
                            <TextInput
                            label = 'State Abbreviation'
                                name="state" 
                            />
                            <TextInput
                            label = 'Max Value of Savings'
                                name="max_value"
                                type='number'
                            />

                        </Form>
                           
                    </div>

                    {requirements}
                </Loading>
            </Container>
        );
    }
}
