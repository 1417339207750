import React, { Component } from 'react';

import { Image, Button, Modal } from 'library';
import { ajax_wrapper, UserValidator } from 'functions';
import { isMobile } from 'react-device-detect';
import { ContactUs } from 'pages';
import { ajax } from 'jquery';

class Header extends Component {
    constructor(props) {
        super(props);

        this.state = {
            show: false,
            contact_us: false,
        };

        this.session_query = false;
    }

    componentDidMount() {
        let session_id = localStorage.getItem('session_id');
        if (!session_id && !this.session_query) {
            this.session_query = true;
            ajax_wrapper('POST', '/api/create_session/', {}, this.load_session);
        }
    }

    load_session(data) {
        localStorage.setItem('session_id', data['session_id']);
    }

    render() {
        let checker = new UserValidator();
        let is_logged_in = checker.logged_id();
        let is_staff = checker.is_staff();

        let items = [];
        let header_class = 'col-xxl-6 col-xl-8 col-12';

        if (!is_logged_in) {
            /*items.push(
                <Button href="/login/" className="nav-item">
                    Login
                </Button>,
            );
            items.push(
                <Button href="/about/" className="nav-item">
                    About
                </Button>,
            );
            items.push(
                <Button href="/contact/" className="nav-item">
                    Contact
                </Button>,
            );
            items.push(
                <Button
                    type="dark"
                    style={{ borderRadius: '100px' }}
                    href="/signup/"
                >
                    Join For Free
                </Button>,
            );*/
        }

        if (is_staff) {
            // Add staff routes to header

            items.push(
                <Button
                    className="nav-item"
                    href="/admin/exemption_list/"
                    style={{ marginRight: '5px' }}
                >
                    Exemptions
                </Button>,
            );
        }

        if (is_logged_in) {
            // Add authed routes to header
            items.push(
                <Button className="nav-item" href="/address_search/">
                    Home Search
                </Button>,
            );
            items.push(
                <Button className="nav-item" href="/about_us/">
                    About
                </Button>,
            );
            items.push(
                <Button className="nav-item" href="/logout/">
                    Logout
                </Button>,
            );

            header_class = 'col-xl-8 col-12';
        }

        if (isMobile) {
            items = [];
        }

        items.push(
            <Button
                type="dark"
                style={{ borderRadius: '100px' }}
                onClick={() => this.setState({ contact_us: true })}
            >
                Contact Us
            </Button>,
        );

        return (
            <div className="container">
                <header className="d-flex justify-content-center row">
                    <div
                        className={header_class}
                        style={{
                            border: 'thin solid #d9d9d9',
                            borderRadius: '100px',
                            background: 'white',
                            padding: '12px 20px',
                            margin: '20px 0px',
                        }}
                    >
                        <div style={{ float: 'right' }}>{items}</div>
                        <Modal
                            style={{ maxWidth: '600px' }}
                            show={this.state.contact_us}
                            onHide={() => this.setState({ contact_us: false })}
                        >
                            <ContactUs />
                        </Modal>
                        <a href="/">
                            <img
                                src="/static/images/logo_dark.svg"
                                style={{ height: '38px' }}
                            ></img>
                        </a>
                    </div>
                </header>
            </div>
        );
    }
}

export default Header;
