import { clear_tokens } from 'functions';

const Logout = () => {
    clear_tokens();
    window.location.href = '/login'

    return null;
};

export default Logout;
