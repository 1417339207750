import React, { Component } from 'react';
import { Button, Modal, Form, TextInput } from 'library';
import { ajax_wrapper, get_global_state } from 'functions';

export default class Review extends Component {

    render() {
        let name = 'Jeremy T'
        let review = "An incredible product that exceeded all my expectations!"

        let image = null;
        if (this.props.image) {
            image = <img src={this.props.image} alt="Reviewer" style={{borderRadius: '50%', width: '150px', height: '150px', objectFit: 'cover', marginBottom: '15px'}} />
        }

        return (
            <div className='col-md-4 col-sm-12'>
                <div style={{backgroundColor: 'white', marginBottom:'20px', borderRadius: '10px', boxShadow: '0 4px 6px rgba(0,0,0,0.1)', padding: '20px', maxWidth: '400px', textAlign: 'center'}}>
                    {image}
                    <h2 style={{margin: 0, color: '#333'}}>{this.props.name}</h2>
                    <p style={{color: '#666', fontStyle: 'italic', marginBottom: '15px'}}>{this.props.review}</p>
                    <div style={{display: 'flex', justifyContent: 'center', color:'#FFD700'}}>
                        ★★★★★
                    </div>
                </div>
            </div>
        )
    }
}

