import React from 'react';
import { Container } from 'library';

const PrivacyPolicy = () => {
  const styles = {
    container: {
      margin: '0 auto',
      padding: '20px',
      fontFamily: 'Arial, sans-serif',
      lineHeight: '1.6',
      color: '#333'
    },
    header: {
      textAlign: 'center',
      marginBottom: '20px'
    },
    title: {
      fontSize: '24px',
      fontWeight: 'bold',
      marginBottom: '5px'
    },
    subtitle: {
      fontSize: '16px',
      fontWeight: 'bold',
      color: '#555',
      marginBottom: '20px'
    },
    introduction: {
      marginBottom: '20px'
    },
    section: {
      marginBottom: '20px'
    },
    sectionTitle: {
      fontSize: '18px',
      fontWeight: 'bold',
      marginBottom: '10px'
    },
    list: {
      paddingLeft: '20px',
      marginBottom: '15px'
    },
    listItem: {
      marginBottom: '5px'
    },
    paragraph: {
      marginBottom: '15px'
    },
    footer: {
      marginTop: '30px',
      borderTop: '1px solid #eee',
      paddingTop: '20px'
    }
  };

  return (
    <Container>

    <div style={styles.container}>
      <div style={styles.header}>
        <div style={styles.title}>Online Privacy Policy</div>
        <div style={styles.subtitle}>Effective Date: March 16, 2025</div>
      </div>
      
      <div style={styles.introduction}>
        <strong>Introduction</strong>
        <p style={styles.paragraph}>
          HomeBase Ventures Inc ("Company," "we," "our," or "us") is committed to protecting your privacy. 
          This Privacy Policy ("Policy") applies to www.yourhomebase.co and any related applications and 
          services (collectively, the "Services"). By using our Services, you consent to the data practices 
          described in this Policy.
        </p>
      </div>

      <div style={styles.section}>
        <div style={styles.sectionTitle}>1. Information We Collect</div>
        <p style={styles.paragraph}>
          We collect both personal and non-personal information to provide and improve our Services. This includes:
        </p>
        <p style={styles.paragraph}><strong>Personal Information:</strong></p>
        <ul style={styles.list}>
          <li style={styles.listItem}>First and last name</li>
          <li style={styles.listItem}>Mailing address</li>
          <li style={styles.listItem}>Email address</li>
          <li style={styles.listItem}>Phone number</li>
          <li style={styles.listItem}>Social Security Number (SSN) or Individual Taxpayer Identification Number (ITIN)</li>
          <li style={styles.listItem}>Payment information (if you purchase products or services)</li>
        </ul>
        <p style={styles.paragraph}><strong>Non-Personal Information:</strong></p>
        <ul style={styles.list}>
          <li style={styles.listItem}>Browser type and version</li>
          <li style={styles.listItem}>IP address</li>
          <li style={styles.listItem}>Pages visited, time spent, and other usage data</li>
        </ul>
        <p style={styles.paragraph}>
          We do not collect personal information unless you voluntarily provide it through account registration, 
          customer service requests, transactions, or participation in promotional events.
        </p>
      </div>

      <div style={styles.section}>
        <div style={styles.sectionTitle}>2. How We Use Your Information</div>
        <p style={styles.paragraph}>
          We use collected information for the following purposes:
        </p>
        <ul style={styles.list}>
          <li style={styles.listItem}>To operate and provide our Services</li>
          <li style={styles.listItem}>To process transactions and manage billing</li>
          <li style={styles.listItem}>To communicate with you regarding updates, promotions, and customer support</li>
          <li style={styles.listItem}>To comply with legal obligations</li>
          <li style={styles.listItem}>To detect and prevent fraudulent or malicious activity</li>
        </ul>
        <p style={styles.paragraph}>
          We may also use anonymized data for analytics and research.
        </p>
      </div>

      <div style={styles.section}>
        <div style={styles.sectionTitle}>3. Sharing of Information</div>
        <p style={styles.paragraph}>
          We do not sell, rent, or lease your personal information. However, we may share information with:
        </p>
        <ul style={styles.list}>
          <li style={styles.listItem}>Service providers (e.g., payment processors, customer support, and marketing services)</li>
          <li style={styles.listItem}>Business partners offering relevant products or services</li>
          <li style={styles.listItem}>Legal authorities when required to comply with laws or protect our rights and users' safety</li>
        </ul>
        <p style={styles.paragraph}>
          Third-party partners are contractually obligated to maintain the confidentiality of your information.
        </p>
      </div>

      <div style={styles.section}>
        <div style={styles.sectionTitle}>4. Your Rights and Choices</div>
        <p style={styles.paragraph}>
          You have the right to:
        </p>
        <ul style={styles.list}>
          <li style={styles.listItem}>Access and update your personal data</li>
          <li style={styles.listItem}>Opt out of promotional emails via the unsubscribe link</li>
          <li style={styles.listItem}>Request deletion of your personal data, subject to certain legal exceptions</li>
          <li style={styles.listItem}>Limit data sharing with third parties (subject to applicable laws)</li>
        </ul>
        <p style={styles.paragraph}>
          To exercise your rights, contact us at admin@yourhomebase.co.
        </p>
      </div>

      <div style={styles.section}>
        <div style={styles.sectionTitle}>5. Security of Your Information</div>
        <p style={styles.paragraph}>
          We implement security measures to protect your personal data. However, no system can be 100% secure. 
          By using our Services, you acknowledge the inherent risks of online data transmission.
        </p>
      </div>

      <div style={styles.section}>
        <div style={styles.sectionTitle}>6. Data Retention</div>
        <p style={styles.paragraph}>
          We retain personal information as long as necessary to provide our Services, comply with legal obligations, 
          or resolve disputes. When no longer needed, data is securely deleted or anonymized.
        </p>
      </div>

      <div style={styles.section}>
        <div style={styles.sectionTitle}>7. Third-Party Links</div>
        <p style={styles.paragraph}>
          Our Services may contain links to third-party websites. We are not responsible for their privacy 
          policies or practices. Please review their policies before providing personal information.
        </p>
      </div>

      <div style={styles.section}>
        <div style={styles.sectionTitle}>8. Changes to This Policy</div>
        <p style={styles.paragraph}>
          We may update this Policy from time to time to reflect changes in our practices, services, 
          or legal requirements. Any significant updates will be communicated through email or a notice 
          on our website. Continued use of our Services constitutes agreement to the updated Policy.
        </p>
      </div>

      <div style={styles.section}>
        <div style={styles.sectionTitle}>9. Contact Us</div>
        <p style={styles.paragraph}>
          If you have any questions or concerns about this Policy, please contact us at: admin@yourhomebase.co
        </p>
      </div>
    </div>
 
      </Container>)
};

export default PrivacyPolicy;