import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import React, { Component } from 'react';
import { ajax_wrapper } from 'functions';
import CheckoutForm from './checkout.js';
import { Container } from 'library';
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe('pk_live_51QHn8ORx9MNACnOxdVe1OGIbtfumjpnFhJVjqigs0aveTYGstt63ZVSCzsfFTPhFesQhh9eHFc0MzYoD3HebGy2F00bWX5iVze');

export default class Stripe extends Component {
    constructor(props) {
      super(props);
      this.state = {
        clientSecret: '',
      };

      this.load_stripe_secret = this.load_stripe_secret.bind(this);
    }

    componentDidMount() {
      // Create PaymentIntent as soon as the page loads
      ajax_wrapper('POST', '/api/stripe_payment_intent/', {'price': this.props.price}, this.load_stripe_secret)
    }

    load_stripe_secret(data) {
      this.setState({clientSecret: data['client_secret']})
    }

    render() {
      const { clientSecret } = this.state;

      const options = {
        clientSecret,
      };

      return (
        <Container>
          {clientSecret && (
            <Elements stripe={stripePromise} options={options}>
              <CheckoutForm />
            </Elements>
          )}
        </Container>
      );
    }
  
};



