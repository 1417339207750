import React, { Component } from 'react';
import { Container } from 'library';

class Question extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showAnswer: false
        }

        this.toggleAnswer = this.toggleAnswer.bind(this);
    }

    toggleAnswer() {
        this.setState({showAnswer: !this.state.showAnswer});
    }
    
    render() {
        let chevron = 'down';
        let answer = null;
        if (this.state.showAnswer) {
            chevron = 'up';
            answer = <p style={{padding:'10px 20px', color:'#696969'}}>{this.props.answer}</p>;
        }

        let backgroundColor = 'rgb(94, 81, 247, .2)';
        if (this.props.background == 'orange') { 
            backgroundColor = 'rgb(247, 94, 81, .2)';
        }

        return (
            <div onClick={this.toggleAnswer}>
                <div style={
                    {backgroundColor: backgroundColor,
                        boxShadow: '1px 1px 5px 2px rgb(94, 81, 247, .2)', 
                        padding:'10px 20px 1px', 
                        borderRadius:'20px', 
                        marginTop:'10px',
                        marginBottom:'15px',
                        cursor:'pointer',
                    }
                    }>
                    <p style={{fontSize:'24px'}}>
                        {this.props.question}
                        <i class={"bi bi-chevron-" + chevron} style={{float: 'right', marginRight:'10px'}}></i>
                    </p>
                    
                </div>
                {answer}
            </div>
        );
    }
}

export default class FAQs extends Component {
    constructor(props) {
        super(props);
    }

    render() {

        return (
            <div className='gradient-background-faqs' style={{padding: '60px 0px'}}>
                <div className="slide-header">
                    <h4>Questions</h4>
                </div>
                <Container>
                    <h4>Popular Topics</h4>

                    <div className='simple-card' style={{marginTop:'40px', paddingBottom:'20px'}}>
                        <div style={{borderBottom:'1px solid #e0e0e0', padding:'10px 0px'}}>
                            <h3 style={{fontSize:'28px'}}><img src='/static/images/house.png' style={{height:'24px', marginRight:'5px'}} />General</h3>
                        </div>
                        <Question background='blue' question='What is HomeBase?' answer='HomeBase helps homeowners save money on property taxes by identifying over-assessments, guiding them through appeals, and helping them understand state-specific exemption requirements. With over 120M properties in our database and advanced AI technology, we continuously analyze your property to find savings opportunities.'/>
                        <Question background='blue' question='How does HomeBase work?' answer='Our platform analyzes your property tax data to determine if you’re overpaying. If eligible, we provide tools to help you appeal or offer a done-for-you filing service. We also help you navigate tax exemptions available in your state.'/>
                        <Question background='blue' question='What else can HomeBase do?' answer='Beyond property taxes, we can also help you save on home insurance, mortgage refinancing, and internet services. Every offer is customized to match your property and specific needs. ' />
                    </div>

                    <div className='simple-card' style={{marginTop:'40px', paddingBottom:'20px'}}>
                        <div style={{borderBottom:'1px solid #e0e0e0', padding:'10px 0px'}}>
                            <h3 style={{fontSize:'28px'}}><img src='/static/images/pricing.png' style={{height:'24px', marginRight:'5px'}} />Pricing and Plans</h3>
                        </div>
                        <Question background='orange' question='Is HomeBase really free to use?' answer='Yes! Our core platform is free to use, we only need your name and email to grant access to the data. You only pay if you choose our filing service'/>
                        <Question background='orange' question='What do I get with the free plan?' answer='You get a free property tax assessment review, an estimate of potential savings, step-by-step guidance for self-filing, and access to exemption eligibility information.'/>
                        <Question background='orange' question='How much does the filing service cost?' answer='Pricing depends on your location and case details. You’ll see the cost on your property page before confirming the service.' />
                        <Question background='orange' question='What is your money-back guarantee?' answer="If we don't meet your service expectations, we'll refund 100% of your fee—no questions asked." />
                    </div>

                    <div className='simple-card' style={{marginTop:'40px', paddingBottom:'20px'}}>
                        <div style={{borderBottom:'1px solid #e0e0e0', padding:'10px 0px'}}>
                            <h3 style={{fontSize:'28px'}}><img src='/static/images/service.png' style={{height:'24px', marginRight:'5px'}} />Service & Process</h3>
                        </div>
                        <Question background='blue' question='How do I know if I’m overpaying on property taxes?' answer='Our system analyzes comparable properties and tax records to determine if your assessment is too high. '/>
                        <Question background='blue' question='How much can I save?' answer="We've helped our customers save an average of $1,000 each. "/>
                        <Question background='blue' question='What if I’m underpaying and my appeal fails?' answer='You are not required to pay the difference if your appeal fails. Your current tax bill remains unchanged.' />
                        <Question background='blue' question='Do you help with property tax exemptions?' answer='Yes! We provide guidance on exemption requirements in all 50 states, including homestead, veteran, and senior exemptions. You can navigate this for free or opt for our paid filing service.'/>
                        <Question background='blue' question='How long does the appeal process take?' answer='Appeal timelines vary by county and state workload, typically taking 6-10 weeks to complete.'/>
                        <Question background='blue' question='What happens if my appeal is denied?' answer='If you used our filing service, you’ll get a full refund. If you filed yourself, you can reapply next year.' />
                    </div>

                    <div className='simple-card' style={{marginTop:'40px', paddingBottom:'20px'}}>
                        <div style={{borderBottom:'1px solid #e0e0e0', padding:'10px 0px'}}>
                            <h3 style={{fontSize:'28px'}}><img src='/static/images/support.png' style={{height:'24px', marginRight:'5px'}} />Support & Account</h3>
                        </div>
                        <Question background='orange' question='Do I need to provide any documents?' answer='For the free review, you would need to provide the relevant document to your local government agencies. If you use our filing service, we will assist you in gathering the right supporting documents for a successful appeal. '/>
                        <Question background='orange' question='Can I track my appeal or exemption filing status?' answer='Yes! You can log into HomeBase to see real-time updates on your appeal or exemption filing internal. Once your appeal or exemption is filed, updates will come from your county goverment office. '/>
                        <Question background='orange' question='How safe is using HomeBase?' answer="Your data's security is our top priority. We protect your information using industry-leading encryption standards and maintain strict confidentiality policies that prevent sharing of sensitive data. As a BBB-accredited business, we're committed to maintaining the highest standards of security and trust." />
                        <Question background='orange' question='How do I contact support?' answer='You can reach us at admin@yourhomebase.co for any questions.' />
                    </div>

                    
                </Container>
                
                
            </div>
        );
    }
}
