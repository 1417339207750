import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { DOLLAR_STYLING } from 'constants';
import { ajax_wrapper, get_global_state, bound_font_size } from 'functions';

import { Loading, Container, Modal } from 'library';
import {
    Exemptions,
    PropertyDetails,
    Comparables,
    Overpaying,
    FAQs,
    Underpaying,
} from 'components';
import { isMobile } from 'react-device-detect';
import { FilingOptions } from 'pages';

export default class PropertyOpportunities extends Component {
    constructor(props) {
        super(props);

        this.state = {
            windowWidth: 0,
            windowHeight: 0,
            property: {},
            exemptions: [],
            comparables: [],
            loaded: false,
            qualify_for_exemptions: null,
            error: null,
            filing: false,
            open_filing_options: false,
            filing_options: {},
        };

        this.get_property = this.get_property.bind(this);
        this.load_property = this.load_property.bind(this);
        this.submit_info = this.submit_info.bind(this);
        this.toggle_filing_options = this.toggle_filing_options.bind(this);
    }

    componentDidMount() {
        this.get_property();

        window.addEventListener('resize', () =>
            this.setState({
                windowWidth: window.innerWidth,
                windowHeight: window.innerHeight,
            }),
        );
    }

    get_property = () => {
        let params = get_global_state()['params'];
        localStorage.setItem('property_id', params['property_id']);
        ajax_wrapper(
            'GET',
            '/api/property_opportunities/',
            { property_id: params['property_id'] },
            this.load_property,
        );
    };

    load_property = (data) => {
        data['loaded'] = true;
        this.setState(data);

        let comparables = data.comparables;
        let average_savings = 0;
        let i = 0;
        let params = get_global_state()['params'];
        let property_id = params['property_id'];

        let session_id = localStorage.getItem('session_id');
        if (session_id) {
            let event_data = {
                session_id: session_id,
                property_id: property_id,
            };
            ajax_wrapper(
                'POST',
                '/api/create_session/',
                event_data,
                console.log,
            );
        }

        if (comparables) {
            if (comparables.length == 0) {
                window.dataLayer.push({
                    property_id: property_id,
                    average_savings: 0,
                    event: 'no_comps',
                });

                let session_id = localStorage.getItem('session_id');
                if (session_id) {
                    let unique_data = {
                        property_id: property_id,
                        average_savings: 0,
                    };
                    let event_data = {
                        session_id: session_id,
                        event_name: 'no_comps',
                        event_data: unique_data,
                    };
                    ajax_wrapper(
                        'POST',
                        '/api/create_event/',
                        event_data,
                        console.log,
                    );
                }
            } else {
                for (let comp of comparables) {
                    average_savings += comp.savings;
                    i++;
                    if (i == 3) {
                        break;
                    }
                }
                if (i != 0) {
                    average_savings = average_savings / i;
                    if (average_savings <= 0) {
                        window.dataLayer.push({
                            property_id: property_id,
                            average_savings: average_savings,
                            event: 'negative_savings',
                        });

                        let session_id = localStorage.getItem('session_id');
                        if (session_id) {
                            let unique_data = {
                                property_id: property_id,
                                average_savings: average_savings,
                            };
                            let event_data = {
                                session_id: session_id,
                                event_name: 'negative_savings',
                                event_data: unique_data,
                            };
                            ajax_wrapper(
                                'POST',
                                '/api/create_event/',
                                event_data,
                                console.log,
                            );
                        }
                    } else {
                        window.dataLayer.push({
                            property_id: property_id,
                            average_savings: average_savings,
                            event: 'positive_savings',
                        });

                        let session_id = localStorage.getItem('session_id');
                        if (session_id) {
                            let unique_data = {
                                property_id: property_id,
                                average_savings: average_savings,
                            };
                            let event_data = {
                                session_id: session_id,
                                event_name: 'positive_savings',
                                event_data: unique_data,
                            };
                            ajax_wrapper(
                                'POST',
                                '/api/create_event/',
                                event_data,
                                console.log,
                            );
                        }
                    }
                }
            }
        }

        if (!this.initializedAds) {
            setTimeout(() => {
                window.initializeMyFinance(true);
                this.initializedAds = true;
            }, 500);
        }

        let ad_tracker_id = localStorage.getItem('ad_tracker');
        if (ad_tracker_id) {
            let address = '';
            if (
                data.property &&
                data.property.propertyInfo &&
                data.property.propertyInfo.address &&
                data.property.propertyInfo.address.label
            ) {
                address = data.property.propertyInfo.address.label;
            }
            ajax_wrapper(
                'POST',
                '/api/facebook_ad_tracker/',
                {
                    id: ad_tracker_id,
                    property_id: property_id,
                    address: address,
                },
                console.log,
            );
        }
    };

    submit_info = (data) => {
        ajax_wrapper(
            'POST',
            '/user/submit_demographics/',
            { data: data },
            console.log,
        );
        this.setState({ qualify_for_exemptions: data });

        window.dataLayer.push({
            event: 'submit_exemption_info',
        });

        let session_id = localStorage.getItem('session_id');
        if (session_id) {
            let unique_data = data;
            let event_data = {
                session_id: session_id,
                event_name: 'submit_exemption_info',
                event_data: unique_data,
            };
            ajax_wrapper('POST', '/api/create_event/', event_data, console.log);
        }
    };

    toggle_filing_options(exemption) {
        this.setState({ filing_options: exemption, open_filing_options: true });
        window.dataLayer.push({
            event: 'show_filing_options',
            exemption_name: exemption.name,
        });

        let session_id = localStorage.getItem('session_id');
        if (session_id) {
            let unique_data = exemption;
            let event_data = {
                session_id: session_id,
                event_name: 'show_filing_options',
                event_data: unique_data,
            };
            ajax_wrapper('POST', '/api/create_event/', event_data, console.log);
        }
    }

    render() {
        if (this.state.error) {
            return (
                <Container>
                    <p>
                        We're sorry. The analysis we do requires a bit more
                        information. We'll follow up with you in an email
                        shortly.
                    </p>
                </Container>
            );
        }

        if (this.state.loaded) {
            //console.log("State", this.state)
            let property = this.state.property;
            let property_id = property.id;
            //console.log("Property", property)
            let address = property.propertyInfo.address;

            let comparables = this.state.comparables;
            let average_savings = 0;
            let i = 0;
            let savings = (
                <span>
                    No Current Comparables, we'll be in touch when we found
                    something.
                </span>
            );

            for (let comp of comparables) {
                average_savings += comp.savings;
                i++;
                if (i == 3) {
                    break;
                }
            }
            if (i != 0) {
                average_savings = average_savings / i;
                if (average_savings <= 0) {
                    savings = (
                        <span>
                            $0, we'll follow up when there is an opportunity.
                            Check below for other ways to save.
                        </span>
                    );
                } else {
                    savings = (
                        <span>
                            {average_savings.toLocaleString(
                                'en-US',
                                DOLLAR_STYLING,
                            )}
                        </span>
                    );
                }
            }

            let hero_cta = <Overpaying />;
            if (!average_savings || average_savings <= 0) {
                hero_cta = <Underpaying />;
            }

            if (!window.secret_react_state['user']) {
                savings = null;
            }

            let header_font_size = bound_font_size(3.5, 70, 35);

            return (
                <div>
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>Property Tax Comparison</title>
                        <meta
                            name="description"
                            content={
                                'A comparison of ' +
                                address.label +
                                ' and similar properties and their property tax rates.'
                            }
                        />
                    </Helmet>

                    <Modal
                        show={this.state.open_filing_options}
                        style={{
                            maxWidth: '800px',
                            padding: '2px',
                            overflow: 'hidden',
                        }}
                        onHide={() =>
                            this.setState({
                                filing_options: {},
                                open_filing_options: false,
                            })
                        }
                    >
                        <FilingOptions
                            property={this.state.property}
                            filing_options={this.state.filing_options}
                            close={() =>
                                this.setState({
                                    filing_options: {},
                                    open_filing_options: false,
                                })
                            }
                        />
                    </Modal>

                    <Loading loaded={this.state.loaded}>
                        {hero_cta}

                        <Container>
                            <div
                                id="property"
                                style={{
                                    textAlign: 'center',
                                    padding: '50px 0px 20px',
                                }}
                            >
                                <span
                                    className="gradient-color"
                                    style={{
                                        fontSize: header_font_size + 'vw',
                                        fontWeight: '500',
                                    }}
                                >
                                    Your Property, Your Savings
                                </span>

                                <br />
                                <span
                                    style={{
                                        fontSize: header_font_size / 2 + 'vw',
                                        fontWeight: '500',
                                        display: 'inline-block',
                                        margin: '0px 45px',
                                    }}
                                >
                                    Discover how much you could save on property
                                    taxes with our smarter, AI-powered
                                    assessment. Start today!
                                </span>
                            </div>
                            <PropertyDetails
                                property={this.state.property}
                                savings={savings}
                                small={isMobile}
                                report_issue={true}
                            />
                        </Container>

                        <div
                            className="gradient-background-light-center"
                            id="comparables"
                        >
                            <div className="slide">
                                <Comparables
                                    property={this.state.property}
                                    comparables={this.state.comparables}
                                    want_to_file={this.toggle_filing_options}
                                    average_savings={average_savings}
                                />
                            </div>
                        </div>

                        <Container className="slide">
                            <div id="exemptions"></div>
                            <Exemptions
                                toggle_filing_options={
                                    this.toggle_filing_options
                                }
                                property={this.state.property}
                                exemptions={this.state.exemptions}
                            />
                        </Container>
                        <Container className="slide">
                            <div className="slide-header" id="insurance">
                                <h4>Other Ways To Save</h4>
                                <h6>
                                    The following are other ways to save money
                                    on your yearly home expenses.
                                </h6>
                            </div>
                            <div
                                className="simple-card"
                                style={{ padding: '10px', marginTop: '20px' }}
                                id="mortgages"
                            >
                                <h6>Mortgage</h6>
                                <div>
                                    Below, there are other mortgage offers that
                                    you could re-finance and potentially save
                                    $100s per month depending on your current
                                    mortgage. Check them out.
                                </div>
                                <div
                                    class="myFinance-widget"
                                    data-ad-id="16d38958-a765-4eed-bb76-88443c3204c0"
                                    data-campaign="homebase-home-equity-multi"
                                    data-sub-id={property_id}
                                ></div>
                            </div>

                            <div
                                className="simple-card"
                                style={{ padding: '10px', marginTop: '20px' }}
                            >
                                <h6>Insurance</h6>
                                <div>
                                    Another cause of high home costs is
                                    insurance. Check and see if you can get a
                                    better rate today.
                                </div>
                                <div
                                    class="myFinance-widget"
                                    data-ad-id="13647411-2e7e-45bc-829d-ce6630321fe6"
                                    data-campaign="homebase-home-insurance-multi"
                                    data-sub-id={property_id}
                                ></div>
                            </div>

                            <div
                                className="simple-card"
                                style={{ padding: '10px', marginTop: '20px' }}
                            >
                                <h6>Internet Providers</h6>
                                <div>
                                    Don't let your monthly internet bill control
                                    your finances. Fight back and get a better
                                    rate.
                                </div>
                                <div
                                    class="myFinance-widget"
                                    data-ad-id="662629fc-3fb4-43c7-856b-b8cfb630f8ec"
                                    data-campaign="chameleon-homebase-zdp"
                                    data-sub-id="Placeholder1"
                                    data-sub-id2="Placeholder2"
                                ></div>
                            </div>
                        </Container>
                        <FAQs />
                    </Loading>
                </div>
            );
        } else {
            return <Loading loaded={this.state.loaded} />;
        }
    }
}
