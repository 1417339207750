import React, { Component } from 'react';
import { Container, MultiSelect, Form } from 'library';
import { Stripe } from 'pages';
import { get_global_state } from 'functions';
import { isMobile } from 'react-device-detect';

export default class CheckoutPage extends Component {
    constructor(props) {
        super(props);
    }

    render() {

        let params = get_global_state()['params']
        let price = params['cost']
        let savings = params['savings']
        let savings_display = <h1 className='gradient-color'>Never pay more in tax until you move.</h1>
        if (savings && parseFloat(savings) != 0) {
            savings_display = <h1 className='gradient-color'>Save ${parseFloat(savings).toFixed(0)} every year until you move.</h1>
        }

        let what_happens_next = <div className='col-md-6 col-sm-12'>
        <div class='simple-card'>
            <h1>
                In under 2 minutes: 
            </h1>
            <p style={{fontSize:'25px'}}>
                You will
                secure your property tax
                appeal, eliminate the
                headache of paperwork, and
                put yourself on the path to
                saving without lifting a finger!
            </p>

            <h1
                style={{ fontSize: '48px', fontWeight: 'bold' }}
                className="gradient-color"
            >
                What happens next?
            </h1>
            <h5>
                1. We'll be in touch over email with the next steps
                to help you save on your property taxes.
            </h5>
            <p>
                a. Clarifying your date of birth, address, and property details.
                <br />
                b. Getting some documents from you. (Ex. Driver's License, Property Deed)
            </p>
            <h5 style={{marginTop: '20px'}}>
                2. We'll file your property tax exemption with your local assessor's office.
            </h5>
            <p>
                a. Including all the difficult paperwork like whether or not you have a reverse mortgage or whether your house was purchased with Tenancy in common or Tenants by entirety, etc. etc.
                <br />
                b. We'll get in contact with your specific county assessor's office to make sure the right person is getting the right forms at the right time.
            </p>
            <h5 style={{marginTop: '20px'}}>
                3. We'll send you a confirmation email with your filing status and approximate time to hear back about your exemption status.
            </h5>
            <h5 style={{marginTop: '20px'}}>
                4. We'll pop the bottle of champagne when you get your exemption.
            </h5>
            <h5 style={{marginTop: '20px'}}>
                OR
            </h5>
            <h5 style={{marginTop: '20px'}}>
                5. We'll refund your money if we can't save you money.
            </h5>
        </div>
    </div>

        let payment = <div className='col-md-6 col-sm-12'>
        <div className='simple-card'>
        <h1 className='gradient-color'>Pay ${(parseInt(price)/100).toFixed(0)} Today.</h1>
        {savings_display}
        <h2>Guaranteed Savings Or Your Money Back.</h2>
        
        <Stripe price={price}></Stripe>

        <div className='col-12' style={{textAlign: 'center', marginTop: '20px'}}>
            <img style={{height:'60px'}} src="https://www.trustsignals.com/hs-fs/hubfs/bbb%20trust%20logo.png?width=864&name=bbb%20trust%20logo.png" />
            <img style={{height:'60px'}} src="https://www.bluematrixmedia.com/wp-content/uploads/2018/04/HTTPS-Blog-04172018.png" />
        </div>
        </div>
    </div>

        let columns = [payment, what_happens_next]
        if (isMobile) {
            columns = [what_happens_next, payment ]
        }
                
        return(
            <Container>
            <div className='row'>
                <h1>{isMobile}</h1>
                {columns}
            </div>
            </Container>
        );
    }
}
