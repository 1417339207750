import React, { Component } from 'react';
import { ajax_wrapper, get_global_state } from 'functions';
import { Loading, Container } from 'library';
import { Helmet } from 'react-helmet';
import { DOLLAR_STYLING } from 'constants';
import {
    PrettyAddress,
    FAQs,
    SignUpModal,
    AddressSearch,
    ComparableMap,
    PropertyDetails,
} from 'components';
import { isMobile } from 'react-device-detect';

class ComparableRow extends Component {
    render() {
        let comparable = this.props.comparable;

        let latlng = comparable.latitude + ',' + comparable.longitude;

        let img_url =
            'https://maps.googleapis.com/maps/api/streetview?location=' +
            latlng +
            '&size=600x400&key=AIzaSyCTeyTeeIoM-qDCe1Z8W7OK0qDVAJ8QzhU';

        let savings = comparable.savings;

        let savings_type = <span>save</span>;
        if (savings < 0) {
            savings_type = <strong style={{ color: 'red' }}>lose</strong>;
            savings = Math.abs(savings);
        }

        return (
            <div
                className="simple-card"
                style={{ overflow: 'hidden', margin: '0px' }}
            >
                <a style={{ textDecoration: 'none' }}>
                    <div
                        className="background-image"
                        style={{
                            backgroundImage: `url(${img_url})`,
                            backgroundPosition: 'center',
                            margin: '-10px -20px 0px',
                            width: 'calc(100% + 40px)',
                        }}
                    ></div>

                    <PrettyAddress
                        property={comparable.property_dict.propertyInfo.address}
                        small={true}
                    />
                </a>
                <div className="line-item">
                    <label>Distance from you:</label>
                    <span>{comparable.distance.toFixed(2)} miles</span>
                </div>
                <div className="page-break"></div>

                <div style={{ textAlign: 'center' }}>
                    <h5>
                        This property is overpaying by{' '}
                        <span className="gradient-color">
                            {savings.toLocaleString('en-US', DOLLAR_STYLING)}
                        </span>{' '}
                        per year.
                    </h5>
                </div>
            </div>
        );
    }
}

export default class LandingPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loaded: false,
            windowWidth: 0,
            windowHeight: 0,
            open_modal: false,
            property: { address: '' },
            state: 'United States',
            nearby_appeal_examples: [],
        };
        this.tracking = false;

        this.select_property = this.select_property.bind(this);

        this.submit_email = this.submit_email.bind(this);
        this.sign_up_callback = this.sign_up_callback.bind(this);

        this.check_address = this.check_address.bind(this);

        this.find_location = this.find_location.bind(this);
        this.find_location_callback = this.find_location_callback.bind(this);
        this.load_nearby_properties = this.load_nearby_properties.bind(this);

        this.save_ad_tracker = this.save_ad_tracker.bind(this);
    }

    componentDidMount() {
        this.find_location();

        let params = get_global_state()['params'];
        if (params['address']) {
            let decodedAddress = decodeURIComponent(params['address']);
            decodedAddress = decodedAddress.replace(', USA', '');

            ajax_wrapper(
                'GET',
                '/api/address_search/',
                { address: decodedAddress },
                this.check_address,
            );
        } else {
            this.setState({ loaded: true });
        }

        if (params['utm_source'] && !this.tracking) {
            this.tracking = true;

            let data = {
                utm_source: params['utm_source'],
                utm_medium: params['utm_medium'],
                utm_campaign: params['utm_campaign'],
                utm_content: params['utm_content'],
            };
            ajax_wrapper(
                'POST',
                '/api/facebook_ad_tracker/',
                data,
                this.save_ad_tracker,
            );
        }

        window.addEventListener('resize', () =>
            this.setState({
                windowWidth: window.innerWidth,
                windowHeight: window.innerHeight,
            }),
        );
    }

    save_ad_tracker(data) {
        console.log('Save Ad Tracker', data);
        localStorage.setItem('ad_tracker', data['id']);
    }

    check_address(data) {
        if (data['data'] && data['data'].length == 1) {
            this.select_property(data['data'][0]);
        } else {
            this.setState({ loaded: true });
        }
    }

    select_property(property) {
        window.dataLayer.push({
            property_id: property.id,
            event: 'select_property',
        });

        let session_id = localStorage.getItem('session_id');
        if (session_id) {
            let unique_data = { property_id: property.id };
            let event_data = {
                session_id: session_id,
                event_name: 'select_property',
                event_data: unique_data,
            };
            ajax_wrapper('POST', '/api/create_event/', event_data, console.log);
        }

        let params = get_global_state()['params'];
        console.log('Params', params);
        if (params && params['email']) {
            this.setState({ property: property });
            let data = {
                email: params['email'],
                password: 'lowermytaxes',
                property_id: property.id,
            };
            data.email = data.email.toLowerCase();
            //console.log("data", data)

            ajax_wrapper(
                'POST',
                '/user/register/',
                data,
                this.sign_up_callback,
            );
        } else {
            this.setState({ property: property });

            if (window.secret_react_state.user) {
                window.location.href = '/property_opportunities/' + property.id;
            }
        }
        //window.location.href = '/property_opportunities/' + property.id
    }

    submit_email(form_data) {
        let data = {
            email: form_data['email'],
            password: 'lowermytaxes',
            property_id: this.state.property.id,
        };
        data.email = data.email.toLowerCase();
        //console.log("data", data)

        ajax_wrapper('POST', '/user/register/', data, this.sign_up_callback);
    }

    sign_up_callback(value) {
        if ('error' in value) {
            this.setState({ error: value.error });
        } else {
            localStorage.setItem('token', value.access);
            localStorage.setItem('refresh_token', value.refresh);
            localStorage.setItem('token_time', new Date());

            window.location.href =
                '/property_opportunities/' + this.state.property.id;
        }
    }

    find_location() {
        console.log('Sending Find Location');
        let url =
            'https://api.ipgeolocation.io/ipgeo?apiKey=7852dde0bf2245f6aeafc1cd6fa0baf9';
        //ajax_wrapper('GET',url, {}, this.find_location_callback)

        const requestOptions = {
            method: 'GET',
            redirect: 'follow',
        };

        fetch(
            'https://api.ipgeolocation.io/ipgeo?apiKey=7852dde0bf2245f6aeafc1cd6fa0baf9',
            requestOptions,
        )
            .then((response) => response.text())
            .then((result) => this.find_location_callback(JSON.parse(result)))
            .catch((error) => console.error(error));
    }

    find_location_callback(data) {
        if (data['country_code3'] == 'USA') {
            this.setState({ state: data['state_prov'] });
        }

        let nearby_prop_data = {
            latitude: data['latitude'],
            longitude: data['longitude'],
            state: data['state_prov'],
        };
        ajax_wrapper(
            'GET',
            '/api/nearby_properties/',
            nearby_prop_data,
            this.load_nearby_properties,
        );
    }

    load_nearby_properties(data) {
        this.setState({ nearby_appeal_examples: data['appeal_list'] });
    }

    render() {
        let nearby_example_list = [];
        let calculator_example_list = [];
        let comparables_for_map = [];
        for (let example of this.state.nearby_appeal_examples) {
            let comparable = example.appeal;
            comparable.id = comparable.property_id;
            example.address = comparable.property_dict.propertyInfo.address;
            comparable.distance = example.distance * 60;

            nearby_example_list.push(
                <div
                    className="col-md-4 col-sm-12"
                    style={{ margin: '20px 0px' }}
                >
                    <ComparableRow comparable={comparable} />
                </div>,
            );
            calculator_example_list.push(
                <div
                    className="col-md-4 col-sm-12"
                    style={{ margin: '20px 0px' }}
                >
                    <PropertyDetails
                        property={comparable['property_dict']}
                        small={true}
                    />
                </div>,
            );

            comparables_for_map.push(comparable);
        }

        let header_font_size = 60;
        let slide_header_font_size = 48;
        if (isMobile) {
            header_font_size = 30;
            slide_header_font_size = 24;
        }

        let address_search_form = (
            <AddressSearch
                input_class="gradient-border-inverted thick"
                select_property={this.select_property}
            />
        );

        return (
            <div>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Save Money On Your Property Taxes and Bills</title>
                    <meta
                        name="description"
                        content="An easy way to find where you are overpaying on property expenses."
                    />
                </Helmet>

                <Loading loaded={this.state.loaded}>
                    <div
                        className="hero-section gradient-background-darker"
                        style={{ marginBottom: '40px', position: 'relative' }}
                    >
                        <div className="bubbles d-none d-lg-block"></div>
                        <Container className="slide">
                            <div
                                style={{
                                    textAlign: 'center',
                                }}
                            >
                                <div
                                    id="propertysearch"
                                    style={{
                                        fontSize: header_font_size + 'px',
                                        fontWeight: '500',
                                        lineHeight: 'normal',
                                    }}
                                >
                                    <div>
                                        1{' '}
                                        <span className="gradient-color">
                                            in
                                        </span>{' '}
                                        2{' '}
                                        <span className="gradient-color">
                                            Homeowners
                                        </span>{' '}
                                        Overpay
                                    </div>
                                    <div className="gradient-color">
                                        Do you?
                                    </div>
                                </div>

                                <div
                                    style={{
                                        width: '60%',
                                        fontSize: header_font_size / 3 + 'px',
                                        display: 'inline-block',
                                        margin: '10px 0px 60px',
                                    }}
                                >
                                    It's fast, free, and easy to find out if
                                    you're overpaying.
                                    <br /> Simply search your address to find
                                    out!
                                </div>
                            </div>

                            <div style={{ margin: '0px 10%' }}>
                                {address_search_form}
                            </div>

                            <div
                                className="row"
                                style={{
                                    margin: '50px 0px',
                                    textAlign: 'center',
                                }}
                            >
                                <div className="col-md-4 col-sm-12">
                                    <h2 className="gradient-color">150M+</h2>
                                    <p style={{ color: 'white' }}>
                                        properties in our database.
                                    </p>
                                </div>

                                <div className="col-md-4 col-sm-12">
                                    <h2 className="gradient-color">$1000s</h2>
                                    <p style={{ color: 'white' }}>
                                        of potential savings for you.
                                    </p>
                                </div>

                                <div className="col-md-4 col-sm-12">
                                    <h2 className="gradient-color">FREE</h2>
                                    <p style={{ color: 'white' }}>
                                        to find your potential savings and how
                                        to file.
                                    </p>
                                </div>
                            </div>
                        </Container>
                    </div>

                    <div className="">
                        <div className="slide">
                            <div className="slide-header">
                                <h1
                                    style={{
                                        fontSize: `${slide_header_font_size}px`,
                                    }}
                                >
                                    {'Learn More About Your '}
                                    <span className="gradient-color">
                                        Homebase
                                    </span>
                                </h1>
                                <h5>
                                    Discover what's happening in your
                                    neighborhood right now - Search free
                                    instantly!
                                </h5>
                            </div>

                            <Loading
                                loaded={
                                    this.state.nearby_appeal_examples.length > 0
                                }
                                cover={true}
                            >
                                <ComparableMap
                                    comparables={comparables_for_map}
                                    final_section={
                                        <div
                                            className="gradient-background-darker"
                                            style={{
                                                padding: '15px 20px',
                                                borderRadius: '16px',
                                            }}
                                        >
                                            Enter your address to find out
                                            exactly how much you could be
                                            overpaying
                                            <div
                                                style={{ height: '10px' }}
                                            ></div>
                                            <AddressSearch
                                                select_property={
                                                    this.select_property
                                                }
                                            />
                                        </div>
                                    }
                                    no_info={true}
                                />
                            </Loading>
                        </div>
                    </div>

                    <div className="">
                        <Container className="slide">
                            <div className="slide-header">
                                <h1
                                    style={{
                                        fontSize: `${slide_header_font_size}px`,
                                    }}
                                >
                                    See How Much You Could Save With Real-Life
                                    Examples
                                </h1>
                                <h5>
                                    Try our tax scale to see which properties in
                                    the area are overpaying - and which ones are
                                    in line!
                                </h5>
                            </div>
                            <div
                                className="row"
                                style={{ margin: '20px 10px' }}
                            >
                                {nearby_example_list}
                            </div>
                        </Container>
                    </div>

                    <div
                        className="gradient-background-darker"
                        style={{
                            borderRadius: '32px',
                            margin: '0px 6px',
                            textAlign: 'center',
                            padding: '40px 20px 60px ',
                        }}
                    >
                        <h1
                            style={{
                                fontSize: `${slide_header_font_size}px`,
                            }}
                        >
                            {'Why '}
                            <span className="orange-text">Wait</span>
                            {'? Find Out About Your '}
                            <span className="orange-text">Taxes</span>
                            {' Today!'}
                        </h1>
                        <h5>Enter your address below</h5>
                        <br />
                        <div style={{ margin: '0px 10%' }}>
                            <AddressSearch
                                select_property={this.select_property}
                            />
                        </div>
                    </div>

                    <div className="">
                        <Container className="slide">
                            <div className="slide-header">
                                <h1
                                    style={{
                                        fontSize: `${slide_header_font_size}px`,
                                    }}
                                >
                                    {'Stop '}
                                    <span className="orange-text">
                                        overpaying ↑
                                    </span>
                                    {' on property taxes'}
                                </h1>
                                <h5>
                                    See how much you could be saving with our
                                    real-time calculator!
                                </h5>
                            </div>
                            <div
                                className="row"
                                style={{ margin: '20px 10px' }}
                            >
                                {calculator_example_list}
                            </div>
                        </Container>
                    </div>

                    <FAQs />

                    <SignUpModal
                        property={this.state.property}
                        close={() => this.setState({ property: {} })}
                    />
                </Loading>
            </div>
        );
    }
}
