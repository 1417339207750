import React, { Component } from 'react';

import {
    APIProvider,
    Map,
    AdvancedMarker,
    CollisionBehavior,
} from '@vis.gl/react-google-maps';

class PropertyMarker extends Component {
    render() {
        let property = this.props.property;

        let display_text = `↑ $${property.savings.toFixed(2)}`;
        let marker_class = 'map-marker overpaying';
        if (!this.props.show_number) {
            /* 
            if (property.savings > 0) {
                display_text = 'Overpaying';
                marker_class = 'map-marker overpaying';
            } else if (property.savings <= 0) {
                display_text = 'Underpaying';
                marker_class = 'map-marker underpaying';
            }
                */

            display_text = `${property.distance.toFixed(2)} miles away`;
            marker_class = 'map-marker distance';
        }

        return (
            <AdvancedMarker
                key={property.id}
                position={{
                    lat: property.latitude,
                    lng: property.longitude,
                }}
                onMouseEnter={() => this.props.select_comp(this.props.property)}
                onMouseLeave={() => this.props.select_comp(null)}
                collisionBehavior={
                    CollisionBehavior.REQUIRED_AND_HIDES_OPTIONAL
                }
            >
                <div className={marker_class} style={{}}>
                    {display_text}
                </div>
            </AdvancedMarker>
        );
    }
}

export default class ComparableMap extends Component {
    constructor(props) {
        super(props);

        this.state = { selected_comp: null };

        this.select_comp = this.select_comp.bind(this);
    }

    select_comp(comp) {
        this.setState({ selected_comp: comp });
    }

    render() {
        let property = this.props.property;

        console.log('Comparables Property', property);

        let marker_list = [];
        let lats = [];
        let lngs = [];

        let home_marker = null;
        if (
            property &&
            property.propertyInfo.latitude &&
            property.propertyInfo.longitude
        ) {
            lats.push(property.propertyInfo.latitude);
            lngs.push(property.propertyInfo.longitude);

            home_marker = (
                <AdvancedMarker
                    key={property.id}
                    position={{
                        lat: property.propertyInfo.latitude,
                        lng: property.propertyInfo.longitude,
                    }}
                >
                    <div className="gradient-border" style={{ padding: '4px' }}>
                        <div
                            className="gradient-border-content"
                            style={{ padding: '4px 6px' }}
                        >
                            <img
                                src="/static/images/house.svg"
                                style={{
                                    height: '18px',
                                    marginRight: '5px',
                                }}
                            />
                            <span style={{ fontSize: '14px' }}>Your Home</span>
                        </div>
                    </div>
                </AdvancedMarker>
            );
        }

        let overpay_count = 0;
        let overpay_amount = 0;
        let overpay_percent = 0;
        for (let comparable of this.props.comparables) {
            marker_list.push(
                <PropertyMarker
                    key={comparable.id}
                    property={comparable}
                    select_comp={this.select_comp}
                    show_number={this.props.show_number}
                />,
            );

            lats.push(comparable.latitude);
            lngs.push(comparable.longitude);

            if (comparable.savings > 0) {
                overpay_count += 1;
                overpay_amount += comparable.savings;
            }
        }

        overpay_percent = (
            (parseFloat(overpay_count) /
                parseFloat(this.props.comparables.length)) *
            100
        ).toFixed(0);
        overpay_amount = (overpay_amount / overpay_count).toFixed(0);

        let bounds_diff = 0.005;
        let map = (
            <div
                style={{
                    padding: '0px',
                    overflow: 'hidden',
                    width: '100%',
                    height: '50vh',
                    minHeight: '450px',
                }}
            ></div>
        );
        if (lats.length > 0) {
            map = (
                <div
                    style={{
                        padding: '0px',
                        overflow: 'hidden',
                        width: '100%',
                        height: '50vh',
                        minHeight: '450px',
                    }}
                >
                    <APIProvider
                        apiKey={'AIzaSyCTeyTeeIoM-qDCe1Z8W7OK0qDVAJ8QzhU'}
                    >
                        <Map
                            mapId="DEMO_MAP_ID"
                            key={'comparables_map'}
                            defaultCenter={{
                                lat: lats[0],
                                lng: lngs[0],
                            }}
                            defaultBounds={{
                                east: Math.max.apply(Math, lngs) + bounds_diff,
                                west: Math.min.apply(Math, lngs) - bounds_diff,
                                north: Math.max.apply(Math, lats) + bounds_diff,
                                south: Math.min.apply(Math, lats) - bounds_diff,
                            }}
                        >
                            {home_marker}
                            {marker_list}
                        </Map>
                    </APIProvider>
                </div>
            );
        }

        return (
            <div style={{ position: 'relative' }}>
                {map}
                {this.props.no_info ? null : (
                    <div className="map-info">
                        <div className="info-item">
                            <span className="orange-text">
                                {overpay_percent}%
                            </span>
                            {' of properties in the area are '}
                            <span className="orange-text">overpaying ↑</span>
                            {' in their taxes'}
                        </div>
                        <div className="info-item">
                            {'The '}
                            <span className="orange-text">average</span>
                            {' overpaying ↑ rate is around '}
                            <span className="orange-text">
                                ${overpay_amount}
                            </span>
                        </div>
                        {/*<div className="info-item">
                            <span className="blue-text">??%</span>
                            {' of properties in the area qualify to receive '}
                            <span className="blue-text">reduced rates</span>
                        </div>*/}
                        <div className="info-item">
                            {this.props.final_section}
                        </div>
                    </div>
                )}
            </div>
        );
    }
}
